const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\wehn-web\\src\\js\\components\\Umbuchung.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { Icon } from "@opendash/icons";
import { Button, Form, Input, InputNumber, Result, Space } from "antd";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { $wehn } from "../state";
import { ArtikelInput } from "./ArtikelInput";
import { LagerInput } from "./LagerInput";
import { UmbuchungState } from "./Umbuchung.state";





export const Umbuchung = observer(function Umbuchung({ type }) {
  const state = React.useMemo(() => new UmbuchungState(type), []);
  const navigate = useNavigate();

  if (state.done) {
    return (
      React.createElement(Result, {
        status: "success",
        title: "Umbuchung erfolgreich" ,
        subTitle: "Die Umbuchung wurde erfolgreich durchgeführt."    ,
        extra: [
          React.createElement(Button, {
            type: "primary",
            key: "restart",
            onClick: () => {
              runInAction(() => {
                state.done = false;
              });
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
, "Neue Umbuchung"

          ),
          React.createElement(Button, {
            key: "home",
            onClick: () => {
              navigate("/select");
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
, "Zur Startseite"

          ),
        ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
      )
    );
  }

  if (type === "artikel" && (!state.started || !state.artikel)) {
    return (
      React.createElement(Form, { layout: "vertical", __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
        , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}, "Standard Umbuchung bei der einzelne Artikel nacheinander umgebucht werden können."


        )
        , React.createElement(ArtikelInput, {
          label: "Bitte einen Artikel auswählen."   ,
          autoFocus: true,
          size: "large",
          value: state.artikel || undefined,
          onChange: (value) => {
            state.setArtikel(value);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
        )
        , React.createElement(Form.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
          , React.createElement(Button, {
            size: "large",
            type: "primary",
            htmlType: "submit",
            disabled: !state.artikel,
            onClick: () => {
              runInAction(() => {
                state.started = true;
              });
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
, "Umbuchung starten"

          )
        )
      )
    );
  }

  if (type === "lager" && (!state.started || !state.startLager)) {
    return (
      React.createElement(Form, { layout: "vertical", __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}
        , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}, "Lager-Umbuchung bei der ein Quell-Lager/-Lagerort ausgewählt wird und dann nacheinander mehrere Buchungen für das gleiche Quell-Lager/-Lagerort durchgeführt werden können."



        )
        , React.createElement(LagerInput, {
          label: "Bitte den Quell-Lagerort scannen."   ,
          autoFocus: true,
          lager: state.startLager,
          lagerort: state.startLagerort,
          bestand: state.artikelBestand,
          onChange: (lager, lagerort) => {
            state.setStartLager(lager, lagerort);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
        )
        , React.createElement(Form.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
          , React.createElement(Button, {
            size: "large",
            type: "primary",
            htmlType: "submit",
            disabled: !state.startLager,
            onClick: () => {
              runInAction(() => {
                state.started = true;
              });
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
, "Umbuchung starten"

          )
        )
      )
    );
  }

  return (
    React.createElement(Form, {
      layout: "vertical",
      onSubmitCapture: () => {
        state.submit();
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 124}}

      , type === "lager" && (
        React.createElement(ArtikelInput, {
          label: "Bitte einen Artikel auswählen."   ,
          autoFocus: true,
          size: "large",
          value: state.artikel || undefined,
          onChange: (value) => {
            state.setArtikel(value);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}
        )
      )

      , state.artikel && (
        React.createElement(Form.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 143}}
          , type === "artikel" && (
            React.createElement('div', { style: { marginBottom: 20 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 145}}
              , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 146}}, "Gewählter Artikel:" )
              , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 147}} )
              , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 148}}, state.artikel.artikelnummer), " - "  , state.artikel.bezeichnung
              , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 149}} )
            )
          )
          , React.createElement(Space, { direction: "horizontal", __self: this, __source: {fileName: _jsxFileName, lineNumber: 152}}
            , React.createElement(Button, {
              size: "large",
              onClick: () => {
                $wehn.setArtikelInfoDrawer(
                  _optionalChain([state, 'access', _ => _.artikel, 'optionalAccess', _2 => _2.artikelnummer]) || null
                );
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 153}}

              , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 161}}, "Artikelinfo und Bestände"  )
              , React.createElement(Icon, { icon: "fa:external-link", __self: this, __source: {fileName: _jsxFileName, lineNumber: 162}} )
            )
            /* <Button
              size="large"
              onClick={() => {
                //...
              }}
            >
              <span>Bestände</span>
              <Icon icon="fa:external-link" />
            </Button> */
          )
        )
      )

      , type === "artikel" && (
        React.createElement(LagerInput, {
          label: "Bitte den Quell-Lagerort scannen."   ,
          autoFocus: true,
          lager: state.startLager,
          lagerort: state.startLagerort,
          bestand: state.artikelBestand,
          onChange: (lager, lagerort) => {
            state.setStartLager(lager, lagerort);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 178}}
        )
      )

      , React.createElement(LagerInput, {
        label: "Bitte den Ziel-Lagerort scannen."   ,
        lager: state.zielLager,
        lagerort: state.zielLagerort,
        bestand: state.artikelBestand,
        onChange: (lager, lagerort) => {
          state.setZielLager(lager, lagerort);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 190}}
      )

      , _optionalChain([state, 'access', _3 => _3.artikel, 'optionalAccess', _4 => _4.seriennummer]) && (
        React.createElement(Form.Item, { label: "Seriennummer", __self: this, __source: {fileName: _jsxFileName, lineNumber: 201}}
          , React.createElement(Input, {
            size: "large",
            placeholder: "Seriennummer hier eingeben"  ,
            value: state.seriennummer,
            onChange: (e) => {
              runInAction(() => {
                state.seriennummer = e.target.value;
              });
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 202}}
          )
        )
      )

      , _optionalChain([state, 'access', _5 => _5.artikel, 'optionalAccess', _6 => _6.chargenverfolgung]) && (
        React.createElement(Form.Item, { label: "Charge", __self: this, __source: {fileName: _jsxFileName, lineNumber: 216}}
          , React.createElement(Input, {
            size: "large",
            placeholder: "Chargennummer hier eingeben"  ,
            value: state.charge,
            onChange: (e) => {
              runInAction(() => {
                state.charge = e.target.value;
              });
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 217}}
          )
        )
      )

      , state.artikel && !state.artikel.seriennummer && (
        React.createElement(Form.Item, { label: `Menge (${state.artikel.mengeneinheit})`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 231}}
          , React.createElement(InputNumber, {
            size: "large",
            style: { width: "100%" },
            placeholder: "Menge hier eingeben"  ,
            value: state.menge,
            decimalSeparator: ",",
            onChange: (menge) => {
              runInAction(() => {
                state.menge = menge;
              });
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 232}}
          )
        )
      )

      , React.createElement(Form.Item, {
        help: state.validationError,
        status: state.validationError && "error",
        hasFeedback: !!state.validationError, __self: this, __source: {fileName: _jsxFileName, lineNumber: 247}}

        , React.createElement(Button, {
          size: "large",
          type: "primary",
          htmlType: "submit",
          disabled: !!state.validationError, __self: this, __source: {fileName: _jsxFileName, lineNumber: 252}}
, "Umbuchen"

        )
      )
    )
  );
});
