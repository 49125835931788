 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { makeAutoObservable, runInAction } from "mobx";
import Parse from "parse";
import { ArtikelBestand, ArtikelEinlagerung } from "../parse-types";

export class LagerOrtState {
   __init() {this.subscription1 = null}
   __init2() {this.subscription2 = null}

   __init3() {this.map = {}}

   __init4() {this.result








 = {}}

  constructor() {;LagerOrtState.prototype.__init.call(this);LagerOrtState.prototype.__init2.call(this);LagerOrtState.prototype.__init3.call(this);LagerOrtState.prototype.__init4.call(this);
    makeAutoObservable(this);
  }

   async update(x) {
    let update = false;

    for (const y of x) {
      if (!y || !y.id || this.map[y.id]) continue;

      update = true;

      this.map[y.id] = y;
    }

    if (update) {
      await this.fetch();
    }
  }

   async fetch() {
    const artikelArray = Object.values(this.map);

    if (artikelArray.length === 0) return;

    const einlagerungenQ = new Parse.Query(ArtikelEinlagerung)
      .containedIn("artikel", artikelArray)
      .include("lager")
      .include("lagerort")
      .limit(1000000);
    const bestaendeQ = new Parse.Query(ArtikelBestand)
      .containedIn("artikel", artikelArray)
      .include("lager")
      .limit(1000000);

    const einlagerungen = await einlagerungenQ.find();
    const bestaende = await bestaendeQ.find();

    // this.unsubscribe();
    // this.subscription1 = await einlagerungenQ.subscribe();
    // this.subscription2 = await bestaendeQ.subscribe();

    // this.subscription1.on("create", (x) => {
    //   x as ArtikelEinlagerung;
    // });

    // this.subscription1.on("update", (x) => {
    //   x as ArtikelEinlagerung;
    // });

    // this.subscription2.on("create", (x) => {
    //   x as ArtikelBestand;
    // });

    // this.subscription2.on("update", (x) => {
    //   x as ArtikelBestand;
    // });

    const result








 = {};

    for (const bestand of bestaende) {
      const artikelId = _optionalChain([bestand, 'access', _ => _.get, 'call', _2 => _2("artikel"), 'optionalAccess', _3 => _3.id]);
      const lagerNr = parseInt(_optionalChain([bestand, 'access', _4 => _4.get, 'call', _5 => _5("lager"), 'optionalAccess', _6 => _6.get, 'call', _7 => _7("sqlRef")]));

      let bestandAllLager = bestand.get("bestandLaufend");

      if (result[artikelId]) {
        bestandAllLager += result[artikelId].bestandAllLager;

        result[artikelId].bestandAllLager = bestandAllLager;

        if (lagerNr > (_optionalChain([result, 'access', _8 => _8[artikelId], 'optionalAccess', _9 => _9.lagerNr]) || Number.MAX_VALUE)) {
          continue;
        }
      }

      result[artikelId] = {
        lagerNr,
        lager: bestand.get("lager").get("sqlRef"),
        lagerName: bestand.get("lager").get("bezeichnung"),
        bestandAllLager,
      };
    }

    for (const einlagerung of einlagerungen) {
      const artikelId = einlagerung.get("artikel").id;
      const lagerNr = parseFloat(
        JSON.parse(_optionalChain([einlagerung, 'access', _10 => _10.get, 'call', _11 => _11("lagerort"), 'optionalAccess', _12 => _12.get, 'call', _13 => _13("sqlRef")]) || "[0,0]").join(
          "."
        )
      );
      const existingLagerNr = _optionalChain([result, 'access', _14 => _14[artikelId], 'optionalAccess', _15 => _15.lagerNr]) || Number.MAX_VALUE;

      if (Number.isInteger(existingLagerNr)) {
        if (Math.floor(lagerNr) > existingLagerNr) {
          continue;
        }
      } else {
        if (lagerNr > existingLagerNr) {
          continue;
        }
      }

      result[artikelId] = {
        lagerNr,
        lagerName: einlagerung.get("lager").get("bezeichnung"),
        lagerOrt:
          _optionalChain([einlagerung, 'access', _16 => _16.get, 'call', _17 => _17("lagerort"), 'optionalAccess', _18 => _18.get, 'call', _19 => _19("lagerort")]) || "Kein Lagerort",
        lager: einlagerung.get("lager").get("sqlRef"),
        bestandAllLager: _optionalChain([result, 'access', _20 => _20[artikelId], 'optionalAccess', _21 => _21.bestandAllLager]) || 0,
      };
    }

    runInAction(() => {
      this.result = result;
    });
  }

   unsubscribe() {
    if (this.subscription1) {
      this.subscription1.unsubscribe();
    }

    if (this.subscription2) {
      this.subscription2.unsubscribe();
    }
  }

   clear() {
    this.map = {};
    this.result = {};

    this.unsubscribe();
  }
}
