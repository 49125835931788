import Parse from "parse";














export class Auftrag extends Parse.Object {
  static __initStatic() {this.className = "Auftrag"}

  constructor(data) {
    super("Auftrag", data );
  }

  get auftragsNummer() {
    return super.get("auftragsNummer");
  }
  set auftragsNummer(value) {
    super.set("auftragsNummer", value);
  }
  get bezeichnung() {
    return super.get("bezeichnung");
  }
  set bezeichnung(value) {
    super.set("bezeichnung", value);
  }
  get sqlCreatedAt() {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value) {
    super.set("sqlCreatedAt", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt() {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value) {
    super.set("sqlUpdatedAt", value);
  }
} Auftrag.__initStatic();

Parse.Object.registerSubclass("Auftrag", Auftrag);
