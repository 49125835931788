import Parse from "parse";




















export class AuftragsarbeitsplaenePosition extends Parse.Object {
  static __initStatic() {this.className = "AuftragsarbeitsplaenePosition"}

  constructor(data) {
    super("AuftragsarbeitsplaenePosition", data );
  }

  get agnr() {
    return super.get("agnr");
  }
  set agnr(value) {
    super.set("agnr", value);
  }
  get arbeitsplatznr() {
    return super.get("arbeitsplatznr");
  }
  set arbeitsplatznr(value) {
    super.set("arbeitsplatznr", value);
  }
  get auftragsstueckliste() {
    return super.get("auftragsstueckliste");
  }
  set auftragsstueckliste(value) {
    super.set("auftragsstueckliste", value);
  }
  get BDENummer() {
    return super.get("BDENummer");
  }
  set BDENummer(value) {
    super.set("BDENummer", value);
  }
  get bezeichnung() {
    return super.get("bezeichnung");
  }
  set bezeichnung(value) {
    super.set("bezeichnung", value);
  }
  get sollzeit() {
    return super.get("sollzeit");
  }
  set sollzeit(value) {
    super.set("sollzeit", value);
  }
  get sqlCreatedAt() {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value) {
    super.set("sqlCreatedAt", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt() {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value) {
    super.set("sqlUpdatedAt", value);
  }
} AuftragsarbeitsplaenePosition.__initStatic();

Parse.Object.registerSubclass("AuftragsarbeitsplaenePosition", AuftragsarbeitsplaenePosition);
