import Parse from "parse";


























export class Artikel extends Parse.Object {
  static __initStatic() {this.className = "Artikel"}

  constructor(data) {
    super("Artikel", data );
  }

  get artikelnummer() {
    return super.get("artikelnummer");
  }
  set artikelnummer(value) {
    super.set("artikelnummer", value);
  }
  get auslaufartikel() {
    return super.get("auslaufartikel");
  }
  set auslaufartikel(value) {
    super.set("auslaufartikel", value);
  }
  get bezeichnung() {
    return super.get("bezeichnung");
  }
  set bezeichnung(value) {
    super.set("bezeichnung", value);
  }
  get chargenverfolgt() {
    return super.get("chargenverfolgt");
  }
  set chargenverfolgt(value) {
    super.set("chargenverfolgt", value);
  }
  get chargenverfolgung() {
    return super.get("chargenverfolgung");
  }
  set chargenverfolgung(value) {
    super.set("chargenverfolgung", value);
  }
  get dispoart() {
    return super.get("dispoart");
  }
  set dispoart(value) {
    super.set("dispoart", value);
  }
  get frei() {
    return super.get("frei");
  }
  set frei(value) {
    super.set("frei", value);
  }
  get gesperrt() {
    return super.get("gesperrt");
  }
  set gesperrt(value) {
    super.set("gesperrt", value);
  }
  get mengeneinheit() {
    return super.get("mengeneinheit");
  }
  set mengeneinheit(value) {
    super.set("mengeneinheit", value);
  }
  get seriennummer() {
    return super.get("seriennummer");
  }
  set seriennummer(value) {
    super.set("seriennummer", value);
  }
  get sqlCreatedAt() {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value) {
    super.set("sqlCreatedAt", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt() {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value) {
    super.set("sqlUpdatedAt", value);
  }
  get suchindex() {
    return super.get("suchindex");
  }
  set suchindex(value) {
    super.set("suchindex", value);
  }
  get techniktext() {
    return super.get("techniktext");
  }
  set techniktext(value) {
    super.set("techniktext", value);
  }
  get zeichnung() {
    return super.get("zeichnung");
  }
  set zeichnung(value) {
    super.set("zeichnung", value);
  }
  get zeichnungIndex() {
    return super.get("zeichnungIndex");
  }
  set zeichnungIndex(value) {
    super.set("zeichnungIndex", value);
  }
} Artikel.__initStatic();

Parse.Object.registerSubclass("Artikel", Artikel);
