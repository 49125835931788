import Parse from "parse";






































export class open_transaction_artikel extends Parse.Object {
  static __initStatic() {this.className = "open_transaction_artikel"}

  constructor(data) {
    super("open_transaction_artikel", data );
  }

  get artikel() {
    return super.get("artikel");
  }
  set artikel(value) {
    super.set("artikel", value);
  }
  get auftrag() {
    return super.get("auftrag");
  }
  set auftrag(value) {
    super.set("auftrag", value);
  }
  get auftragposition() {
    return super.get("auftragposition");
  }
  set auftragposition(value) {
    super.set("auftragposition", value);
  }
  get auftragsstueckliste() {
    return super.get("auftragsstueckliste");
  }
  set auftragsstueckliste(value) {
    super.set("auftragsstueckliste", value);
  }
  get auftragsstuecklistenPosition() {
    return super.get("auftragsstuecklistenPosition");
  }
  set auftragsstuecklistenPosition(value) {
    super.set("auftragsstuecklistenPosition", value);
  }
  get charge() {
    return super.get("charge");
  }
  set charge(value) {
    super.set("charge", value);
  }
  get gebucht() {
    return super.get("gebucht");
  }
  set gebucht(value) {
    super.set("gebucht", value);
  }
  get geplant() {
    return super.get("geplant");
  }
  set geplant(value) {
    super.set("geplant", value);
  }
  get invalid() {
    return super.get("invalid");
  }
  set invalid(value) {
    super.set("invalid", value);
  }
  get lager() {
    return super.get("lager");
  }
  set lager(value) {
    super.set("lager", value);
  }
  get lagerort() {
    return super.get("lagerort");
  }
  set lagerort(value) {
    super.set("lagerort", value);
  }
  get menge() {
    return super.get("menge");
  }
  set menge(value) {
    super.set("menge", value);
  }
  get request() {
    return super.get("request");
  }
  set request(value) {
    super.set("request", value);
  }
  get response() {
    return super.get("response");
  }
  set response(value) {
    super.set("response", value);
  }
  get seriennummer() {
    return super.get("seriennummer");
  }
  set seriennummer(value) {
    super.set("seriennummer", value);
  }
  get successful() {
    return super.get("successful");
  }
  set successful(value) {
    super.set("successful", value);
  }
  get undo() {
    return super.get("undo");
  }
  set undo(value) {
    super.set("undo", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
  get uuid() {
    return super.get("uuid");
  }
  set uuid(value) {
    super.set("uuid", value);
  }
} open_transaction_artikel.__initStatic();

Parse.Object.registerSubclass("open_transaction_artikel", open_transaction_artikel);
