const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\wehn-web\\src\\js\\components\\ArtikelEntnahme.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { Icon } from "@opendash/icons";
import {
  Alert,
  Badge,
  Button,
  Divider,
  Input,
  InputNumber,
  Modal,
  Result,
  Select,
  Space,
  Spin,
  Tabs,
} from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import { $wehn } from "../state";
import {

  ArtikelEntnahmeState,
} from "./ArtikelEntnahme.state";
import { BuchungenListe } from "./BuchungenListe";







export const ArtikelEntnahme = observer(function ArtikelEntnahme({
  config,
  onSave,
  onCancel,
}) {
  const state = React.useMemo(
    () => new ArtikelEntnahmeState(config, onSave),
    []
  );

  const navigate = useNavigate();

  const tabSwipeHandler = useSwipeable({
    onSwipedRight: (eventData) => {
      state.setTab(state.currentTab - 1);
    },
    onSwipedLeft: (eventData) => {
      state.setTab(state.currentTab + 1);
    },
  });

  if (state.loading) {
    return React.createElement(Spin, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 55}} );
  }

  return (
    React.createElement(React.Fragment, null
      , React.createElement(ConfirmDialog, { state: state, __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}} )

      , React.createElement('div', { style: { width: "100%" }, ...tabSwipeHandler, __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
        , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}, _optionalChain([state, 'access', _ => _.artikel, 'optionalAccess', _2 => _2.get, 'call', _3 => _3("artikelnummer")]))

        , React.createElement('span', { style: { float: "right" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
          , React.createElement(Badge, {
            count: `${state.mengeIst} / ${state.mengeSoll}`,
            style: { backgroundColor: "#12bd45", float: "right" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
          )
        )

        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 72}} )
        , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 73}} )

        , React.createElement(Button, {
          onClick: () => {
            // navigate(`/artikel/${state.artikel?.get("artikelnummer")}`);
            $wehn.setArtikelInfoDrawer(
              _optionalChain([state, 'access', _4 => _4.artikel, 'optionalAccess', _5 => _5.get, 'call', _6 => _6("artikelnummer")]) || null
            );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}

          , _optionalChain([state, 'access', _7 => _7.artikel, 'optionalAccess', _8 => _8.get, 'call', _9 => _9("bezeichnung")]), " "
          , React.createElement(Icon, { icon: "fa:external-link-square", __self: this, __source: {fileName: _jsxFileName, lineNumber: 84}} )
        )

        , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 87}} )
        , React.createElement(Tabs, {
          activeKey: "" + state.currentTab,
          onTabClick: (key, event) => {
            state.setTab(parseInt(key));
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}

          , React.createElement(Tabs.TabPane, { tab: React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}, "Entnahme"), key: "1", __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
            , React.createElement(Dialog, { key: "1", state: state, __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}} )
          )
          , React.createElement(Tabs.TabPane, { tab: React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}, "Rücklagerung"), key: "2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}
            , React.createElement(Dialog, { key: "2", state: state, __self: this, __source: {fileName: _jsxFileName, lineNumber: 98}} )
          )
          , React.createElement(Tabs.TabPane, {
            tab: 
              React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 102}}, "Buchungen"
                , " "
                , React.createElement(Badge, {
                  count: state.buchungen.length || 0,
                  style: { backgroundColor: "#cccccc" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
                )
              )
            ,
            key: "3", __self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}

            , React.createElement(BuchungenListe, { buchungen: state.buchungen, __self: this, __source: {fileName: _jsxFileName, lineNumber: 112}} )
          )
        )
      )
    )
  );
});

const Dialog = observer

(({ state }) => {
  const bestaendeSelectOptions = React.useMemo(
    () =>
      Object.values(state.bestaende).map((x) => ({
        label: ` ${_optionalChain([x, 'access', _10 => _10.get, 'call', _11 => _11("lager"), 'optionalAccess', _12 => _12.get, 'call', _13 => _13("sqlRef")])} - ${x.get(
          "bestandLaufend"
        )} ${_optionalChain([state, 'access', _14 => _14.artikel, 'optionalAccess', _15 => _15.get, 'call', _16 => _16("mengeneinheit")])}`,
        value: x.id,
      })),
    [state.selectedBestandId]
  );

  const einlagerungenSelectOptions = React.useMemo(
    () =>
      state.selectedBestandId
        ? Object.values(state.einlagerungen)
            .filter(
              (x) => _optionalChain([x, 'access', _17 => _17.get, 'call', _18 => _18("lager"), 'optionalAccess', _19 => _19.id]) === _optionalChain([state, 'access', _20 => _20.buchung, 'access', _21 => _21.get, 'call', _22 => _22("lager"), 'optionalAccess', _23 => _23.id])
            )
            .map((x) => ({
              label: `Lagerort ${_optionalChain([x, 'access', _24 => _24.get, 'call', _25 => _25("lager"), 'optionalAccess', _26 => _26.get, 'call', _27 => _27("sqlRef")])} / ${_optionalChain([x
, 'access', _28 => _28.get, 'call', _29 => _29("lagerort")
, 'optionalAccess', _30 => _30.get, 'call', _31 => _31("lagerort")])} - ${x.get("menge")} ${_optionalChain([state, 'access', _32 => _32.artikel, 'optionalAccess', _33 => _33.get, 'call', _34 => _34(
                "mengeneinheit"
              )])}`,
              value: x.id,
            }))
        : [],
    [state.selectedEinlagerungId, state.selectedBestandId]
  );

  React.useEffect(() => {
    if (!state.selectedEinlagerungId && einlagerungenSelectOptions.length > 0) {
      state.setEinlagerung(_optionalChain([einlagerungenSelectOptions, 'access', _35 => _35[0], 'optionalAccess', _36 => _36.value]));
    }
  }, [einlagerungenSelectOptions]);

  if (!state.rücklagerung && bestaendeSelectOptions.length === 0) {
    return React.createElement(Result, { status: "warning", title: "Artikel ist nicht mehr auf Lager"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 160}} );
  }

  if (state.locked) {
    return React.createElement(Result, { status: "info", title: "Buchung wurde abgeschickt"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 164}} );
  }

  return (
    React.createElement(React.Fragment, null
      , !state.rücklagerung && (
        React.createElement(React.Fragment, null
          , bestaendeSelectOptions.length > 0 && (
            React.createElement(Select, {
              size: "large",
              style: { width: "100%" },
              placeholder: "Bestand auswählen" ,
              options: bestaendeSelectOptions,
              value: state.selectedBestandId,
              onChange: (id) => {
                state.setBestand(id);
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 172}}
            )
          )

          , einlagerungenSelectOptions.length > 0 && (
            React.createElement(Select, {
              size: "large",
              style: { width: "100%", marginTop: 10 },
              placeholder: "Einlagerung auswählen" ,
              options: einlagerungenSelectOptions,
              value: state.selectedEinlagerungId,
              onChange: (id) => {
                state.setEinlagerung(id);
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 185}}
            )
          )
        )
      )

      , state.rücklagerung && (
        React.createElement(React.Fragment, null
          , React.createElement(Input, {
            placeholder: "Lager scannen" ,
            allowClear: true,
            style: { width: "100%" },
            onChange: (e) => {
              state.validateLagerId(e.target.value);
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 201}}
          )
        )
      )

      , (state.selectedBestandId || state.rücklagerung) && (
        React.createElement(React.Fragment, null
          , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 214}})
          , React.createElement(Space, { direction: "vertical", style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 215}}
            , _optionalChain([state, 'access', _37 => _37.artikel, 'optionalAccess', _38 => _38.get, 'call', _39 => _39("chargenverfolgung")]) && (
              React.createElement(Input, {
                placeholder: "Chargennummer",
                style: { width: "100%" },
                value: state.charge,
                onChange: (e) => {
                  state.setCharge(e.target.value);
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 217}}
              )
            )

            , _optionalChain([state, 'access', _40 => _40.artikel, 'optionalAccess', _41 => _41.get, 'call', _42 => _42("seriennummer")]) && (
              React.createElement(React.Fragment, null
                , React.createElement(Input, {
                  placeholder: "Seriennummer",
                  style: { width: "100%" },
                  value: state.seriennummer,
                  onChange: (e) => {
                    state.setSeriennummer(e.target.value);
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 229}}
                )
                , React.createElement(Button, {
                  type: "primary",
                  size: "large",
                  style: { width: "100%" },
                  disabled: 
                    !state.lagerValid ||
                    (state.rücklagerung
                      ? 1 > state.mengeIst
                      : 1 > state.maxMenge)
                  ,
                  onClick: () => {
                    state.setMenge(1);
                    state.buchen();
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 237}}
, "Artikel mit Seriennummer entnehmen"

                )
              )
            )

            , !_optionalChain([state, 'access', _43 => _43.artikel, 'optionalAccess', _44 => _44.get, 'call', _45 => _45("seriennummer")]) && (
              React.createElement(React.Fragment, null
                , React.createElement(InputNumber, {
                  type: "number",
                  value: state.menge,
                  onChange: (test) => {
                    if (test === null) {
                      state.setMenge(0);
                    } else {
                      state.setMenge(test);
                    }
                  },
                  placeholder: "Zu entnehmende Menge"  ,
                  style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 259}}
                )

                , (state.rücklagerung
                  ? state.menge === 0 || state.menge > state.mengeIst
                  : state.menge === 0 || state.menge > state.maxMenge) && (
                  React.createElement(Alert, { message: "Bitte Menge kontrollieren"  , type: "error", __self: this, __source: {fileName: _jsxFileName, lineNumber: 276}} )
                )

                , React.createElement(Button, {
                  type: "primary",
                  size: "large",
                  style: { width: "100%" },
                  disabled: 
                    !state.lagerValid ||
                    (state.rücklagerung
                      ? state.menge === 0 || state.menge > state.mengeIst
                      : state.menge === 0 || state.menge > state.maxMenge)
                  ,
                  onClick: () => {
                    state.buchen();
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 279}}

                  , state.rücklagerung ? "Rücklagern" : "Entnehmen"
                )
              )
            )
          )
        )
      )
    )
  );
});

const ConfirmDialog = observer

(({ state }) => {
  React.useEffect(() => {
    if (state.showConfirmDialog) {
      const spanStyle = {
        display: "block",
        marginBottom: 6,
      };
      const dateEntryStyle = { float: "right" };

      const okButtonStyle = state.buchung.get("geplant")
        ? {
            backgroundColor: "green",
            color: "white",
            borderColor: "darkgreen",
          }
        : {
            backgroundColor: "#ffe58f",
            color: "#faad14",
            borderColor: "#faad14",
          };

      Modal.confirm({
        title:
          (state.rücklagerung ? "Rücklagerung " : "Entnahme ") +
          _optionalChain([state, 'access', _46 => _46.artikel, 'optionalAccess', _47 => _47.get, 'call', _48 => _48("artikelnummer")]),
        icon: state.buchung.get("geplant") ? (
          React.createElement(Icon, { icon: "fa:check-circle", style: { color: "green" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 332}} )
        ) : (
          React.createElement(Icon, { icon: "fa:exclamation-circle", style: { color: "#faad14" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 334}} )
        ),
        okButtonProps: { style: okButtonStyle },
        okText:
          "" +
          Math.abs(state.menge) +
          " " +
          _optionalChain([state, 'access', _49 => _49.artikel, 'optionalAccess', _50 => _50.get, 'call', _51 => _51("mengeneinheit")]) +
          (state.rücklagerung ? " rücklagern" : " entnehmen"),
        cancelText: "abbrechen",
        content: (
          React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 345}}
            , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 346}}, _optionalChain([state, 'access', _52 => _52.artikel, 'optionalAccess', _53 => _53.get, 'call', _54 => _54("bezeichnung")]))
            , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 347}} )
            , React.createElement('span', { style: spanStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 348}}, "Geplante Menge:"

              , React.createElement('b', { style: dateEntryStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 350}}
                , state.mengeSoll, " " , _optionalChain([state, 'access', _55 => _55.artikel, 'optionalAccess', _56 => _56.get, 'call', _57 => _57("mengeneinheit")])
              )
            )

            , state.seriennummer && (
              React.createElement('span', { style: spanStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 356}}, "Seriennummer:"

                , React.createElement('b', { style: dateEntryStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 358}}, state.seriennummer)
              )
            )

            , state.charge && (
              React.createElement('span', { style: spanStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 363}}, "Charge:"

                , React.createElement('b', { style: dateEntryStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 365}}, state.charge)
              )
            )
            , React.createElement('span', { style: spanStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 368}}, "Bereits entnommen:"

              , React.createElement('b', { style: dateEntryStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 370}}
                , state.mengeIst || 0, " " , _optionalChain([state, 'access', _58 => _58.artikel, 'optionalAccess', _59 => _59.get, 'call', _60 => _60("mengeneinheit")])
              )
            )

            , !state.rücklagerung && (
              React.createElement('span', { style: spanStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 376}}, "Lager-Verfügbarkeit :"

                , React.createElement('b', { style: dateEntryStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 378}}
                  , state.maxMenge, " " , _optionalChain([state, 'access', _61 => _61.artikel, 'optionalAccess', _62 => _62.get, 'call', _63 => _63("mengeneinheit")])
                )
              )
            )
            , state.rücklagerung && (
              React.createElement('span', { style: spanStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 384}}, "Lager:"

                , React.createElement('b', { style: dateEntryStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 386}}
                  , _optionalChain([state, 'access', _64 => _64.buchung, 'access', _65 => _65.get, 'call', _66 => _66("lager"), 'optionalAccess', _67 => _67.get, 'call', _68 => _68("sqlRef")]) || ""
                )
              )
            )

            , state.rücklagerung && (
              React.createElement('span', { style: spanStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 393}}, "Lagerort:"

                , React.createElement('b', { style: dateEntryStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 395}}
                  , _optionalChain([state, 'access', _69 => _69.buchung, 'access', _70 => _70.get, 'call', _71 => _71("lagerort"), 'optionalAccess', _72 => _72.get, 'call', _73 => _73("lagerort")]) || ""
                )
              )
            )
            , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 400}})
            , React.createElement('span', { style: spanStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 401}}
              , state.rücklagerung ? "Rücklagerung:" : "Entnahme:"
              , React.createElement('b', { style: dateEntryStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 403}}
                , state.menge, " " , _optionalChain([state, 'access', _74 => _74.artikel, 'optionalAccess', _75 => _75.get, 'call', _76 => _76("mengeneinheit")])
              )
            )
          )
        ),
        onOk() {
          state.buchen(true);
        },
        onCancel() {
          state.setConfirmDialog(false);
        },
      });
    }
  }, [state.showConfirmDialog]);

  return null;
});
