import Parse from "parse";













export class Lager extends Parse.Object {
  static __initStatic() {this.className = "Lager"}

  constructor(data) {
    super("Lager", data );
  }

  get bezeichnung() {
    return super.get("bezeichnung");
  }
  set bezeichnung(value) {
    super.set("bezeichnung", value);
  }
  get dynamisch() {
    return super.get("dynamisch");
  }
  set dynamisch(value) {
    super.set("dynamisch", value);
  }
  get reserviert() {
    return super.get("reserviert");
  }
  set reserviert(value) {
    super.set("reserviert", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
} Lager.__initStatic();

Parse.Object.registerSubclass("Lager", Lager);
