import Parse from "parse";






















export class Auftragsstueckliste extends Parse.Object {
  static __initStatic() {this.className = "Auftragsstueckliste"}

  constructor(data) {
    super("Auftragsstueckliste", data );
  }

  get auftrag() {
    return super.get("auftrag");
  }
  set auftrag(value) {
    super.set("auftrag", value);
  }
  get auftragPosition() {
    return super.get("auftragPosition");
  }
  set auftragPosition(value) {
    super.set("auftragPosition", value);
  }
  get BDENummer() {
    return super.get("BDENummer");
  }
  set BDENummer(value) {
    super.set("BDENummer", value);
  }
  get bezeichnung() {
    return super.get("bezeichnung");
  }
  set bezeichnung(value) {
    super.set("bezeichnung", value);
  }
  get hierarchyEbene() {
    return super.get("hierarchyEbene");
  }
  set hierarchyEbene(value) {
    super.set("hierarchyEbene", value);
  }
  get hierarchyParent() {
    return super.get("hierarchyParent");
  }
  set hierarchyParent(value) {
    super.set("hierarchyParent", value);
  }
  get hierarchyRang() {
    return super.get("hierarchyRang");
  }
  set hierarchyRang(value) {
    super.set("hierarchyRang", value);
  }
  get sqlCreatedAt() {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value) {
    super.set("sqlCreatedAt", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt() {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value) {
    super.set("sqlUpdatedAt", value);
  }
} Auftragsstueckliste.__initStatic();

Parse.Object.registerSubclass("Auftragsstueckliste", Auftragsstueckliste);
