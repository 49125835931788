import Parse from "parse";

































export class Auftragsposition extends Parse.Object {
  static __initStatic() {this.className = "Auftragsposition"}

  constructor(data) {
    super("Auftragsposition", data );
  }

  get artikel() {
    return super.get("artikel");
  }
  set artikel(value) {
    super.set("artikel", value);
  }
  get auftrag() {
    return super.get("auftrag");
  }
  set auftrag(value) {
    super.set("auftrag", value);
  }
  get auftragPosition() {
    return super.get("auftragPosition");
  }
  set auftragPosition(value) {
    super.set("auftragPosition", value);
  }
  get bezeichnung() {
    return super.get("bezeichnung");
  }
  set bezeichnung(value) {
    super.set("bezeichnung", value);
  }
  get dispoArt() {
    return super.get("dispoArt");
  }
  set dispoArt(value) {
    super.set("dispoArt", value);
  }
  get fertig() {
    return super.get("fertig");
  }
  set fertig(value) {
    super.set("fertig", value);
  }
  get forms() {
    return super.relation("forms");
  }
  get gesperrt() {
    return super.get("gesperrt");
  }
  set gesperrt(value) {
    super.set("gesperrt", value);
  }
  get mengeAngefordert() {
    return super.get("mengeAngefordert");
  }
  set mengeAngefordert(value) {
    super.set("mengeAngefordert", value);
  }
  get mengeAngefordertNichtBestellt() {
    return super.get("mengeAngefordertNichtBestellt");
  }
  set mengeAngefordertNichtBestellt(value) {
    super.set("mengeAngefordertNichtBestellt", value);
  }
  get mengeBereitgestellt() {
    return super.get("mengeBereitgestellt");
  }
  set mengeBereitgestellt(value) {
    super.set("mengeBereitgestellt", value);
  }
  get mengeBestelltKunde() {
    return super.get("mengeBestelltKunde");
  }
  set mengeBestelltKunde(value) {
    super.set("mengeBestelltKunde", value);
  }
  get mengeBestelltWehn() {
    return super.get("mengeBestelltWehn");
  }
  set mengeBestelltWehn(value) {
    super.set("mengeBestelltWehn", value);
  }
  get mengeGeliefert() {
    return super.get("mengeGeliefert");
  }
  set mengeGeliefert(value) {
    super.set("mengeGeliefert", value);
  }
  get mengeIst() {
    return super.get("mengeIst");
  }
  set mengeIst(value) {
    super.set("mengeIst", value);
  }
  get mengeSoll() {
    return super.get("mengeSoll");
  }
  set mengeSoll(value) {
    super.set("mengeSoll", value);
  }
  get sqlCreatedAt() {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value) {
    super.set("sqlCreatedAt", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt() {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value) {
    super.set("sqlUpdatedAt", value);
  }
  get teilfertig() {
    return super.get("teilfertig");
  }
  set teilfertig(value) {
    super.set("teilfertig", value);
  }
} Auftragsposition.__initStatic();

Parse.Object.registerSubclass("Auftragsposition", Auftragsposition);
