const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\wehn-web\\src\\js\\page\\WehnEntnahmeList.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { List, message, TreeSelect, } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EntnahmeListFilter } from "../components/EntnahmeListFilter";
import { EntnahmeListItem } from "../components/EntnahmeListItem";
import { Layout } from "../components/Layout";
import { $wehn } from "../state";




export const WehnEntnahmeList = observer(function WehnEntnahmeList({
  type,
}) {
  const navigate = useNavigate();
  const { id } = useParams();

  React.useEffect(
    () => {
      if (type === "Auftragspostitionen") {
        $wehn.orders.list.initAuftragspositionen();
      } else if (type === "Auftragsstückliste" && id) {
        $wehn.orders.list.initAuftragsstueckliste(id);
      } else {
        console.log("Invalid URL for WehnEntnahmeList", type, id);
      }

      return () => {
        $wehn.orders.list.unsubscribe();
      };
    },
    type === "Auftragspostitionen"
      ? [_optionalChain([$wehn, 'access', _ => _.orders, 'access', _2 => _2.auftrag, 'optionalAccess', _3 => _3.id]), null]
      : type === "Auftragsstückliste"
      ? [_optionalChain([$wehn, 'access', _4 => _4.orders, 'access', _5 => _5.auftrag, 'optionalAccess', _6 => _6.id]), id]
      : [null, null]
  );

  return (
    React.createElement(Layout, {
      title: type,
      description: "Entnahme",
      padding: false,
      back: -1,
      breadcrumb: [
        {
          path: `/`,
          label: "Startseite",
        },
        {
          path: "/wehn/entnahme",
          label: "Entnahme",
        },
        {
          path: "",
          label: type,
        },
      ],
      extra: 
        React.createElement(React.Fragment, null
          , type === "Auftragsstückliste" && id && (
            React.createElement(TreeSelect, {
              size: "large",
              style: { width: "100%" },
              dropdownStyle: { maxHeight: "80vh", overflow: "auto" },
              placeholder: "Stückliste auswählen" ,
              treeDefaultExpandAll: true,
              value: id,
              treeData: $wehn.orders.toTree(),
              onChange: (id) =>
                navigate(`/wehn/entnahme/auftragsstueckliste/${id}`)
              , __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
            )
          )
        )
      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}

      , React.createElement(EntnahmeListFilter, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 79}} )

      , !($wehn.orders.list.rows.length === 0 && $wehn.orders.list.filter) && (
        React.createElement(List, {
          loading: $wehn.orders.list.loading,
          dataSource: Object.values($wehn.orders.list.rows),
          rowKey: "id",
          renderItem: (item) => (
            React.createElement(EntnahmeListItem, {
              item: item,
              cb: () => {
                if (item.disabled) {
                  return void message.error(item.disabledMessage);
                }

                return void $wehn.buchung.openDrawer({
                  type: item.type,
                  id: item.id,
                });
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}
            )
          ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
        )
      )
    )
  );
});
