const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\wehn-web\\src\\js\\components\\ArtikelEntnahmeDrawer.tsx";import { Drawer } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { $wehn } from "../state";
import { ArtikelEntnahme } from "./ArtikelEntnahme";

export const ArtikelEntnahmeDrawer = observer(function ArtikelEntnahmeDrawer() {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();

  const showBuchung = params.get("buchung") === "true";

  React.useEffect(() => {
    if ($wehn.buchung.drawer) {
      params.set("buchung", "true");
      setParams(params);
    }
  }, [$wehn.buchung.drawer]);

  React.useEffect(() => {
    if (!showBuchung) {
      $wehn.buchung.closeDrawer();
    }

    if (showBuchung && !$wehn.buchung.drawer) {
      params.delete("buchung");
      setParams(params);
    }
  }, [showBuchung]);

  if ($wehn.buchung.drawer) {
    return (
      // @ts-ignore
      React.createElement(Drawer, {
        title: "Buchung",
        open: true,
        height: window.innerHeight,
        placement: "bottom",
        onClose: () => {
          // $wehn.buchung.closeDrawer();
          navigate(-1);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}

        , React.createElement(ArtikelEntnahme, {
          config: $wehn.buchung.drawer,
          onSave: () => {
            // $wehn.buchung.closeDrawer();
            navigate(-1);
          },
          onCancel: () => {
            // $wehn.buchung.closeDrawer();
            navigate(-1);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
        )
      )
    );
  }

  return React.createElement(Drawer, { title: "Buchung", open: false, placement: "bottom", __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}} );
});
