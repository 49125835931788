import Parse from "parse";















export class Lagerort extends Parse.Object {
  static __initStatic() {this.className = "Lagerort"}

  constructor(data) {
    super("Lagerort", data );
  }

  get lager() {
    return super.get("lager");
  }
  set lager(value) {
    super.set("lager", value);
  }
  get lagerort() {
    return super.get("lagerort");
  }
  set lagerort(value) {
    super.set("lagerort", value);
  }
  get menge() {
    return super.get("menge");
  }
  set menge(value) {
    super.set("menge", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
} Lagerort.__initStatic();

Parse.Object.registerSubclass("Lagerort", Lagerort);
