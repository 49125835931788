import Parse from "parse";
















export class ArtikelBestand extends Parse.Object {
  static __initStatic() {this.className = "ArtikelBestand"}

  constructor(data) {
    super("ArtikelBestand", data );
  }

  get artikel() {
    return super.get("artikel");
  }
  set artikel(value) {
    super.set("artikel", value);
  }
  get bestandLaufend() {
    return super.get("bestandLaufend");
  }
  set bestandLaufend(value) {
    super.set("bestandLaufend", value);
  }
  get lager() {
    return super.get("lager");
  }
  set lager(value) {
    super.set("lager", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
} ArtikelBestand.__initStatic();

Parse.Object.registerSubclass("ArtikelBestand", ArtikelBestand);
