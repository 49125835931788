const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\wehn-web\\src\\js\\components\\EntnahmeListItem.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { Icon } from "@opendash/icons";
import { Badge, List, Space, Statistic, Tag } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import styled from "styled-components";



const Rows = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 0px 6px;
`;

const Row = styled.div`
  display: flex;
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px; */
  width: 100%;
`;

const ColSmall = styled.div`
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.75);
  font-size: 13px;
`;

const ColLeft = styled.div`
  width: 100%;
  display: flex;
  flex: 1 1;

  font-size: 16px;
  font-weight: bold;

  > span {
    word-wrap: break-word;
    display: inline-block;
    word-break: break-all;
  }
`;

const ColRight = styled.div`
  float: right;
  text-align: right;

  .ant-tag {
    margin: 0;
  }

  .ant-statistic-content {
    font-size: 16px;
  }

  .ant-statistic-content-value,
  .ant-statistic-content-suffix {
    word-wrap: break-word;
    display: inline-block;
    word-break: break-all;
  }
`;






export const EntnahmeListItem = observer(function EntnahmeListItem({
  item,
  cb,
}) {
  let precisionSoll = _optionalChain([("" + item.mengeSoll), 'access', _ => _.split, 'call', _2 => _2("."), 'access', _3 => _3[1], 'optionalAccess', _4 => _4.length]) || 0;
  let precisionIst = _optionalChain([("" + item.mengeIst), 'access', _5 => _5.split, 'call', _6 => _6("."), 'access', _7 => _7[1], 'optionalAccess', _8 => _8.length]) || 0;

  const LItem = (
    React.createElement(List.Item, {
      className: item.gesperrt ? "item-disabled" : "",
      style: {
        paddingLeft: "3px",
        borderRight: item.gesperrtFlag
          ? "6px solid #DD0000"
          : item.doneFlag
          ? "6px solid #00cc00"
          : item.mailFlag
          ? "6px solid #fcc25c"
          : item.inProgressFlag
          ? "6px solid #fFee5c"
          : "6px solid gray",
      },
      onClick: () => {
        cb(item);
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}

      , React.createElement(Rows, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 98}}
        , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
          , React.createElement(ColLeft, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}
            , item.artikelId && (
              React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 102}}
                , React.createElement(Icon, { icon: "fa:inventory", __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}} ), " " , item.lagerLabel
              )
            )
          )
          , React.createElement(ColRight, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 107}}
            , item.gesperrtFlag && React.createElement(Tag, { color: "#DD0000", __self: this, __source: {fileName: _jsxFileName, lineNumber: 108}}, "Gesperrt")
            , item.doneFlag && React.createElement(Tag, { color: "#00cc00", __self: this, __source: {fileName: _jsxFileName, lineNumber: 109}}, "Fertig")
            , item.mailFlag && !item.doneFlag && (
              React.createElement(Tag, { color: "#fcc25c", __self: this, __source: {fileName: _jsxFileName, lineNumber: 111}}
                , item.bestellt
                  ? "Bestellt"
                  : item.angefordert
                  ? "Angefordert"
                  : "Nicht angefordert"
              )
            )
            , item.inProgressFlag && (
              React.createElement(Tag, { color: "#fFee5c", style: { color: "grey" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 120}}, "Teilentnommen"

              )
            )
            , !(
              item.gesperrtFlag ||
              item.doneFlag ||
              item.mailFlag ||
              item.inProgressFlag
            ) && React.createElement('h4', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 129}}, "Entnahme")
          )
        )
        , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 132}}
          , React.createElement(ColLeft, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 133}}
            , React.createElement(Space, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 134}}
              , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 135}}
                , React.createElement(Icon, { icon: "fa:cube", __self: this, __source: {fileName: _jsxFileName, lineNumber: 136}} )
                , item.artikelNr ? " " + item.artikelNr : " O-Teil"
              )
              , React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 139}}
                , React.createElement(Icon, { icon: "fa:thumbtack", __self: this, __source: {fileName: _jsxFileName, lineNumber: 140}} )
                , item.position
              )
            )
          )
          , React.createElement(ColRight, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 145}}
            , React.createElement(Statistic, {
              value: " " + (item.mengeIst.toFixed(precisionIst) || "0"),
              suffix: 
                "/ " +
                item.mengeSoll.toFixed(precisionSoll) +
                " " +
                item.artikelEinheit
              ,
              prefix: 
                React.createElement(React.Fragment, null
                  , item.gesperrtFlag && (
                    React.createElement(Icon, { style: { color: "#DD0000" }, icon: "fa:ban", __self: this, __source: {fileName: _jsxFileName, lineNumber: 157}} )
                  )
                  , item.doneFlag && (
                    React.createElement(Icon, { style: { color: "#00cc00" }, icon: "fa:check-circle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 160}} )
                  )
                  , item.mailFlag && (
                    React.createElement(Icon, {
                      style: { color: "#fcc25c" },
                      icon: "fa:shopping-cart", __self: this, __source: {fileName: _jsxFileName, lineNumber: 163}}
                    )
                  )
                )
              , __self: this, __source: {fileName: _jsxFileName, lineNumber: 146}}
            )
          )
        )
        , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 173}}
          , React.createElement(ColSmall, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 174}}, item.bezeichnung)
        )
      )
    )
  );
  if (item.lagerNichtAusreichend) {
    return (
      React.createElement(Badge.Ribbon, { text: React.createElement(Icon, { icon: "fa:exclamation", __self: this, __source: {fileName: _jsxFileName, lineNumber: 181}} ), color: "red", __self: this, __source: {fileName: _jsxFileName, lineNumber: 181}}
        , LItem
      )
    );
  } else {
    return LItem;
  }
});
