import Parse from "parse";


















export class open_transaction_arbeit extends Parse.Object {
  static __initStatic() {this.className = "open_transaction_arbeit"}

  constructor(data) {
    super("open_transaction_arbeit", data );
  }

  get agnr() {
    return super.get("agnr");
  }
  set agnr(value) {
    super.set("agnr", value);
  }
  get end() {
    return super.get("end");
  }
  set end(value) {
    super.set("end", value);
  }
  get reference() {
    return super.get("reference");
  }
  set reference(value) {
    super.set("reference", value);
  }
  get start() {
    return super.get("start");
  }
  set start(value) {
    super.set("start", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
} open_transaction_arbeit.__initStatic();

Parse.Object.registerSubclass("open_transaction_arbeit", open_transaction_arbeit);
