import Parse from "parse";















export class Materialdisposition extends Parse.Object {
  static __initStatic() {this.className = "Materialdisposition"}

  constructor(data) {
    super("Materialdisposition", data );
  }

  get artikel() {
    return super.get("artikel");
  }
  set artikel(value) {
    super.set("artikel", value);
  }
  get dispotyp() {
    return super.get("dispotyp");
  }
  set dispotyp(value) {
    super.set("dispotyp", value);
  }
  get menge() {
    return super.get("menge");
  }
  set menge(value) {
    super.set("menge", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
} Materialdisposition.__initStatic();

Parse.Object.registerSubclass("Materialdisposition", Materialdisposition);
