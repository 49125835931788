const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\wehn-web\\src\\js\\components\\EntnahmeListFilter.tsx";import { useEventListener } from "@opendash/core";
import { Alert, Button, Drawer, Input, List, Result } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { $wehn } from "../state";

export const EntnahmeListFilter = observer(function EntnahmeListFilter() {
  useEventListener(
    "keydown",
    React.useCallback((e) => {
      // @ts-ignore
      if (e.target.tagName.toUpperCase() === "INPUT") return;
      if (e.key.length > 1 && e.key.toLowerCase() !== "unidentified") return;

      console.log(e.key);

      $wehn.orders.list.setFilter(e.key);

      e.preventDefault();
      let el = document.getElementById("wehn-entnahme-list-filter-input");
      if (el) el.focus();
    }, [])
  );

  if ($wehn.orders.list.filter === null) return null;

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Alert, {
        type: "info",
        message: "Aktiver Filter" ,
        description: 
          React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
            , React.createElement(Input, {
              id: "wehn-entnahme-list-filter-input",
              value: $wehn.orders.list.filter || "",
              autoFocus: true,
              onChange: (e) => $wehn.orders.list.setFilter(e.target.value), __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
            )
          )
        ,
        closable: true,
        onClose: () => $wehn.orders.list.setFilter(null),
        style: {
          position: "fixed",
          left: "0px",
          right: "0px",
          top: "var(--opendash-header-height)",
          zIndex: "999",
          width: "100%",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
      )

      , $wehn.orders.list.rows.length === 0 && (
        React.createElement(Result, {
          title: "Artikel in aktueller Stückliste nicht gefunden!"     ,
          extra: 
            React.createElement(Button, {
              type: "primary",
              key: "console",
              onClick: () => {
                if ($wehn.orders.list.filter === null) {
                  $wehn.orders.list.setFilter("");
                } else {
                  $wehn.orders.list.searchFilter();
                }
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
, "Artikel suchen und entnehmen"

            )
          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
        )
      )

      , React.createElement(Drawer, {
        title: "Ungeplante Entnahme" ,
        open: $wehn.orders.list.unplannedItems !== null,
        placement: "bottom",
        height: "100vh",
        onClose: () => $wehn.orders.list.setFilter(null), __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}

        , !!$wehn.orders.list.unplannedItems && (
          React.createElement(List, {
            itemLayout: "vertical",
            dataSource: $wehn.orders.list.unplannedItems,
            locale: {
              emptyText: `Keine Artikel für '${$wehn.orders.list.filter}' gefunden.`,
            },
            renderItem: (item) => (
              React.createElement(List.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}
                , React.createElement(List.Item.Meta, {
                  title: item.artikelnummer,
                  description: item.bezeichnung, __self: this, __source: {fileName: _jsxFileName, lineNumber: 91}}
                )
                , React.createElement(Button, {
                  onClick: () => {
                    $wehn.orders.list.setFilter(null);

                    // navigate(
                    //   `/entnahme/Artikel/${item.artikel.objectId}?auftrag=${list.auftrag.objectId}&aufpos=${list.auftragPosition.objectId}&stckliste=${currentList}`
                    // );

                    // $wehn.buchung.openDrawer({
                    //   type: "Artikel",
                    //   artikelId: item?.id,
                    //   auftragId: $wehn.orders.auftrag!.id,
                    //   auftragpositionId: list.auftragPosition?.objectId,
                    //   stückliste: currentList,
                    // });
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}
, "entnehmen"

                )
              )
            ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}}
          )
        )
      )
    )
  );
});
