 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { makeAutoObservable, runInAction } from "mobx";
import * as Parse from "parse";
import { Artikel, Buchung, Materialdisposition } from "../parse-types";

export class ArtikelInfoShowState {
   __init() {this.loading = true}

   __init2() {this.artikel = undefined}
   __init3() {this.buchungen = []}

   __init4() {this.dispostatus = {
    anforderung: false,
    bestellt: false,
    fertigung: false,
    qs: false,
  }}

  constructor(id) {;ArtikelInfoShowState.prototype.__init.call(this);ArtikelInfoShowState.prototype.__init2.call(this);ArtikelInfoShowState.prototype.__init3.call(this);ArtikelInfoShowState.prototype.__init4.call(this);
    makeAutoObservable(this);

    this.fetchData(id);

    // console.log("ArtikelInfoShowState", id, this);
  }

   async fetchData(id) {
    try {
      const artikel = await new Parse.Query(Artikel)
        .equalTo("artikelnummer", id)
        .first();

      const buchungen = await new Parse.Query(Buchung)
        .include("lager")
        .include("lagerort")
        .equalTo("artikel", artikel)
        .descending("buchungsdatum")
        .limit(10)
        .find();

      const materialdisposition = await new Parse.Query(Materialdisposition)
        .equalTo("artikel", artikel)
        .first();

      const dispotyp = _optionalChain([materialdisposition, 'optionalAccess', _ => _.get, 'call', _2 => _2("dispotyp"), 'optionalAccess', _3 => _3.toLowerCase, 'call', _4 => _4()]);

      runInAction(() => {
        this.artikel = artikel;
        this.buchungen = buchungen;

        this.dispostatus = {
          anforderung: dispotyp === "an",
          bestellt: dispotyp === "be",
          fertigung: dispotyp === "sk",
          qs: dispotyp === "qs",
        };

        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}
