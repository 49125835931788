import Parse from "parse";


















export class ArtikelEinlagerung extends Parse.Object {
  static __initStatic() {this.className = "ArtikelEinlagerung"}

  constructor(data) {
    super("ArtikelEinlagerung", data );
  }

  get artikel() {
    return super.get("artikel");
  }
  set artikel(value) {
    super.set("artikel", value);
  }
  get lager() {
    return super.get("lager");
  }
  set lager(value) {
    super.set("lager", value);
  }
  get lagerort() {
    return super.get("lagerort");
  }
  set lagerort(value) {
    super.set("lagerort", value);
  }
  get menge() {
    return super.get("menge");
  }
  set menge(value) {
    super.set("menge", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
} ArtikelEinlagerung.__initStatic();

Parse.Object.registerSubclass("ArtikelEinlagerung", ArtikelEinlagerung);
