import Parse from "parse";












export class UserMeta extends Parse.Object {
  static __initStatic() {this.className = "UserMeta"}

  constructor(data) {
    super("UserMeta", data );
  }

  get ausweis() {
    return super.get("ausweis");
  }
  set ausweis(value) {
    super.set("ausweis", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
} UserMeta.__initStatic();

Parse.Object.registerSubclass("UserMeta", UserMeta);
