 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { makeAutoObservable, runInAction } from "mobx";
import Parse from "parse";
import {
  Artikel,
  Auftragsposition,
  Auftragsstueckliste,
  AuftragsstuecklistenPosition,
} from "../parse-types";
import { LagerOrtState } from "./LagerOrtState";









































export class EntnahmeListService {
   __init() {this.loading = true}

  

   __init2() {this.map = {}}
   __init3() {this.lager = new LagerOrtState()}

   __init4() {this.subscription = null}

   __init5() {this.filter = null}
   __init6() {this.unplannedItems = null}

  constructor(entnahme) {;EntnahmeListService.prototype.__init.call(this);EntnahmeListService.prototype.__init2.call(this);EntnahmeListService.prototype.__init3.call(this);EntnahmeListService.prototype.__init4.call(this);EntnahmeListService.prototype.__init5.call(this);EntnahmeListService.prototype.__init6.call(this);
    makeAutoObservable(this);

    this.entnahme = entnahme;
  }

  get rows() {
    return Object.values(this.map)
      .filter((x) => {
        if (this.filter === null) return true;

        return x.searchIndex.includes(this.filter.toLowerCase());
      })
      .sort((a, b) => this.sortItems(a, b));
  }

  get bestand() {
    return this.lager.result;
  }

   setFilter(v) {
    this.filter = v;

    if (v === null) {
      this.unplannedItems = null;
    }
  }

   async searchFilter() {
    if (!this.filter) {
      runInAction(() => {
        this.unplannedItems = null;
      });

      return;
    }

    const result = await new Parse.Query(Artikel)
      .contains("suchindex", this.filter.toLowerCase())
      .find();

    runInAction(() => {
      this.unplannedItems = result;
    });
  }

   async initAuftragsstueckliste(id) {
    this.map = {};
    this.loading = true;

    const stueckliste = await new Parse.Query(Auftragsstueckliste).get(id);

    if (!stueckliste) return;

    const query = new Parse.Query(AuftragsstuecklistenPosition)
      .equalTo("auftragsstueckliste", stueckliste)
      .include("artikel")
      .limit(1000000);

    const result = await query.find();

    this.subscription = await query.subscribe();

    this.subscription.on("create", (x) => {
      this.handleAuftragsstuecklistenPosition(
        x ,
        true
      );
    });

    this.subscription.on("update", (x) => {
      this.handleAuftragsstuecklistenPosition(
        x ,
        true
      );
    });

    await this.lager.update(
      result.map((x) => x.artikel )
    );

    for (const x of result) {
      this.handleAuftragsstuecklistenPosition(x);
    }

    runInAction(() => {
      this.loading = false;
    });
  }

   handleAuftragsstuecklistenPosition(
    x,
    update = false
  ) {
    this.setListItem(
      {
        id: x.id,
        type: "AuftragsstuecklistenPosition",
        bezeichnung: x.bezeichnung || "",
        mengeIst: x.mengeIst || 0,
        mengeSoll: x.mengeSoll || 0,
        mengeAngefordertNichtBestellt: x.mengeAngefordertNichtBestellt || 0,
        mengeBestelltWehn: x.mengeBestelltWehn || 0,
        position: x.position || "",
        fertig: x.fertig || false,
        gesperrt: x.gesperrt || false,
      },
      x.artikel ,
      update
    );
  }

   async initAuftragspositionen() {
    if (!this.entnahme.auftrag) return;

    this.map = {};
    this.loading = true;

    try {
      const auftrag = this.entnahme.auftrag;

      const query = new Parse.Query(Auftragsposition)
        .equalTo("auftrag", auftrag)
        .containedIn("dispoArt", ["K", "E"])
        .include("artikel")
        .limit(1000000);

      const result = await query.find();

      this.subscription = await query.subscribe();

      this.subscription.on("create", (x) => {
        this.handleAuftragsposition(x , true);
      });

      this.subscription.on("update", (x) => {
        this.handleAuftragsposition(x , true);
      });

      await this.lager.update(
        result.map((x) => x.artikel )
      );

      for (const x of result) {
        this.handleAuftragsposition(x);
      }

      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      console.error(error);
    }
  }

   handleAuftragsposition(x, update = false) {
    if (!((x.mengeBereitgestellt || 0) > 0 || (x.mengeAngefordert || 0) > 0)) {
      return;
    }

    this.setListItem(
      {
        id: x.id,
        type: "Auftragsposition",
        bezeichnung: x.bezeichnung || "",
        mengeIst: x.mengeIst || 0,
        mengeSoll: x.mengeSoll || 0,
        mengeAngefordertNichtBestellt: x.mengeAngefordertNichtBestellt || 0,
        mengeBestelltWehn: x.mengeBestelltWehn || 0,
        position: x.auftragPosition || "",
        fertig: x.fertig || false,
        gesperrt: x.gesperrt || false,
      },
      x.artikel ,
      update
    );
  }

   async setListItem(
    item,
    artikel,
    update
  ) {
    if (update && _optionalChain([artikel, 'optionalAccess', _ => _.id])) {
      await this.lager.update([artikel]);
    }

    const mengeFehlt = item.mengeSoll - item.mengeIst;
    const angefordert = item.mengeAngefordertNichtBestellt > 0;
    const bestellt = item.mengeBestelltWehn > 0;

    const doneFlag =
      item.fertig &&
      !(
        (item.mengeAngefordertNichtBestellt > 0 ||
          item.mengeBestelltWehn > 0) &&
        item.mengeIst == 0 &&
        item.mengeSoll == 0
      );

    const begonnenFlag = !doneFlag && item.mengeIst > 0;

    const inProgressFlag = !doneFlag && item.mengeIst > 0;

    const mailFlag =
      item.mengeAngefordertNichtBestellt > 0 || item.mengeBestelltWehn > 0;
    const gesperrtFlag =
      !(item.mengeAngefordertNichtBestellt > 0 || item.mengeBestelltWehn > 0) &&
      item.gesperrt &&
      !item.fertig;

    const bestand = (_optionalChain([artikel, 'optionalAccess', _2 => _2.id]) && this.bestand[artikel.id]) || null;

    const lagerNr = _optionalChain([bestand, 'optionalAccess', _3 => _3.lagerNr]) || 0;
    const lagerLabel = formatLagerort(bestand);

    const lagerNichtAusreichend =
      (_optionalChain([artikel, 'optionalAccess', _4 => _4.id]) &&
        item.mengeSoll > item.mengeIst &&
        (!_optionalChain([bestand, 'optionalAccess', _5 => _5.lagerNr]) || (_optionalChain([bestand, 'optionalAccess', _6 => _6.bestandAllLager]) || 0) < mengeFehlt)) ||
      false;

    const lagerKeinBestandFlag =
      (_optionalChain([artikel, 'optionalAccess', _7 => _7.id]) && !doneFlag && !begonnenFlag && !_optionalChain([bestand, 'optionalAccess', _8 => _8.lagerNr])) || false;

    const searchIndex = JSON.stringify([
      item.bezeichnung,
      _optionalChain([artikel, 'optionalAccess', _9 => _9.bezeichnung]),
      _optionalChain([artikel, 'optionalAccess', _10 => _10.artikelnummer]),
    ]).toLowerCase();

    let disabled = false;
    let disabledMessage = null;

    if (!disabled && !_optionalChain([artikel, 'optionalAccess', _11 => _11.id])) {
      disabled = true;
      disabledMessage = "O-Teil können nicht gebucht werden.";
    }

    if (!disabled && item.gesperrt) {
      disabled = true;
      disabledMessage =
        "Die Position ist gesperrt und kann nicht gebucht werden.";
    }

    this.map[item.id] = {
      ...item,

      artikelId: _optionalChain([artikel, 'optionalAccess', _12 => _12.id]) || null,
      artikelNr: _optionalChain([artikel, 'optionalAccess', _13 => _13.get, 'call', _14 => _14("artikelnummer")]) || null,
      artikelEinheit: _optionalChain([artikel, 'optionalAccess', _15 => _15.get, 'call', _16 => _16("mengeneinheit")]) || "?",

      lagerNr,
      lagerLabel,
      lagerNichtAusreichend,
      lagerKeinBestandFlag,

      mengeFehlt,
      angefordert,
      bestellt,
      doneFlag,
      begonnenFlag,
      inProgressFlag,
      mailFlag,
      gesperrtFlag,

      disabled,
      disabledMessage,

      searchIndex,
    };
  }

   unsubscribe() {
    this.map = {};
    this.lager.clear();

    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

   sortItems(a, b) {
    if (a.gesperrtFlag !== b.gesperrtFlag) {
      if (a.gesperrtFlag) return 1;
      return -1;
    }
    if (a.doneFlag !== b.doneFlag) {
      if (a.doneFlag) return 1;
      return -1;
    }
    if (!a.artikelId !== !b.artikelId) {
      if (!a.artikelId) return 1;
      return -1;
    }
    if (a.mailFlag !== b.mailFlag) {
      if (a.mailFlag) return 1;
      return -1;
    }
    if (a.lagerKeinBestandFlag !== b.lagerKeinBestandFlag) {
      if (a.lagerKeinBestandFlag) return 1;
      return -1;
    }
    if (a.begonnenFlag !== b.begonnenFlag) {
      if (a.begonnenFlag) return 1;
      return -1;
    }

    //TODO if lager1 - lager2 ==0 -> Artikelnr
    return a.lagerNr - b.lagerNr;
  }
}

function formatLagerort(x) {
  if (!_optionalChain([x, 'optionalAccess', _17 => _17.lagerNr])) {
    return "Kein Bestand";
  }

  return (
    x.lager.match(/.{1,3}/g).join("-") +
    (x.lagerOrt || "" ? "/" : "") +
    ((x.lagerOrt || "").match(/.{1,3}/g) || []).join("-")
  )
    .toString()
    .replace(".", " - ");
}
