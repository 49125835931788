import "antd/dist/antd.min.css";
import "./parse.config";

import { init, StorageAdapterLS } from "@opendash/core";
import { registerIconPack } from "@opendash/icons";
import { ParsePlugin } from "@opendash/plugin-parse";
import { Global } from "./components/Global";
import { $wehn } from "./state";

import { WehnEntnahme } from "./page/WehnEntnahme";
import { WehnEntnahmeList } from "./page/WehnEntnahmeList";
import { WehnUmbuchung } from "./page/WehnUmbuchung";
import { WehnArtikel } from "./page/WehnArtikel";
import { WehnArtikelInfo } from "./page/WehnArtikelInfo";

init("opendash", async (factory) => {
  const url = new URL(window.location.href);

  const isEmbedded =
    !!url.searchParams.get("embedded") ||
    window.localStorage.getItem("opendash/embedded") === "true";

  if (isEmbedded) {
    window.localStorage.setItem("opendash/embedded", "true");

    url.searchParams.delete("embedded");

    window.history.replaceState(null, "", url.href);
  }

  // Logo:

  factory.ui.setLogoImage(require("../logo/icon-long.png"));

  if (isEmbedded) {
    factory.ui.disableHeader();
    factory.ui.disableFooter();
  }

  // Icons
  // @ts-ignore
  registerIconPack(await import("@opendash/icons/dist/fa-regular.json"));

  // Translations:

  factory.registerLanguage("de", "Deutsch");

  factory.registerTranslationResolver(
    "de",
    "app",
    () => import("./translations/de.json")
  );

  factory.registerAntDesignTranslation(
    "de",
    // @ts-ignore
    () => import("antd/lib/locale/de_DE")
  );

  // Adapter + Plugins

  factory.registerDeviceStorageAdapter(new StorageAdapterLS());

  await factory.use(
    new ParsePlugin({
      useLiveQueries: false,
      authLdapActive: true,
    })
  );

  // UI

  factory.registerGlobalComponent(Global);

  // Routes

  factory.registerRoute({
    path: "/",
    redirectPath: "/wehn/entnahme",
  });

  factory.registerRoute({
    path: "/wehn/entnahme",
    component: async () => ({ default: WehnEntnahme }),
  });

  factory.registerRoute({
    path: "/wehn/entnahme/auftragsstueckliste/:id",
    component: async () => ({ default: WehnEntnahmeList }),
    props: {
      type: "Auftragsstückliste",
    },
  });

  factory.registerRoute({
    path: "/wehn/entnahme/auftragspositionen",
    component: async () => ({ default: WehnEntnahmeList }),
    props: {
      type: "Auftragspostitionen",
    },
  });

  factory.registerRoute({
    path: "/wehn/umbuchung/:type",
    component: async () => ({ default: WehnUmbuchung }),
  });

  factory.registerRoute({
    path: "/wehn/artikel",
    component: async () => ({ default: WehnArtikel }),
  });

  factory.registerRoute({
    path: "/wehn/artikel/:artikel",
    component: async () => ({ default: WehnArtikelInfo }),
  });

  // Navigation

  const routes = [
    {
      id: "wehn/entnahme",
      label: "Entnahme",
      children: [
        {
          label: "Entnahme",
          url: "/wehn/entnahme",
        },
      ],
    },
    {
      id: "wehn/umbuchung",
      label: "Umbuchung",
      children: [
        {
          label: "Artikel-Umbuchung",
          url: "/wehn/umbuchung/artikel",
        },
        {
          label: "Lager-Umbuchung",
          url: "/wehn/umbuchung/lager",
        },
      ],
    },
    {
      id: "wehn/info",
      label: "Sonstiges",
      children: [
        {
          label: "Artikelsuche",
          url: "/wehn/artikel",
        },
      ],
    },
  ];

  let i = 0;
  for (const group of routes) {
    factory.registerStaticNavigationGroup({
      id: group.id,
      label: group.label,
      // icon: "fa:hammer",
      order: i++,
    });

    let j = 0;
    for (const item of group.children) {
      factory.registerStaticNavigationItem({
        id: group.id + item.url,
        group: group.id,
        place: "sidebar",
        order: j++,

        label: item.label,
        // icon: "fa:home",

        link: item.url,

        routeCondition: "**",
        activeCondition: [item.url, item.url + "/**/*"],
        // permission: item.permission,
      });
    }
  }
}).then(() => {
  $wehn.init();
});
