const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\wehn-web\\src\\js\\components\\BestandListe.tsx";import { Table } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { BestandListeState } from "./BestandListe.state";





export const BestandListe = observer(function BestandListe({
  artikelId,
}) {
  const state = React.useMemo(
    () => new BestandListeState(artikelId),
    [artikelId]
  );

  // console.log(JSON.stringify(state.data));

  if (state.data.length === 0) {
    return React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}, "Keine Bestände gefunden."  );
  }

  return (
    React.createElement(Table, {
      size: "small",
      dataSource: state.data,
      rowKey: (row) => row.id,
      pagination: false,
      loading: state.loading,
      defaultExpandAllRows: true,
      expandable: {
        defaultExpandAllRows: true,
        // showExpandColumn: false,
        expandIcon: () => null,
      },
      columns: [
        {
          title: "Lager/Lagerort",
          key: "label",
          dataIndex: "label",
        },
        {
          title: "Menge",
          key: "menge",
          dataIndex: "menge",
        },
      ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
    )
  );
});
