 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import * as Parse from "parse";
import { makeAutoObservable, runInAction } from "mobx";

import { ArtikelBestand, ArtikelEinlagerung } from "../parse-types";

export class ArtikelBestandState {
   __init() {this.loading = true}
  
   __init2() {this.einlagerungen = []}
   __init3() {this.bestaende = []}

  constructor(artikel) {;ArtikelBestandState.prototype.__init.call(this);ArtikelBestandState.prototype.__init2.call(this);ArtikelBestandState.prototype.__init3.call(this);
    this.artikel = artikel;

    makeAutoObservable(this);

    this.fetchData();
  }

   async fetchData() {
    try {
      const einlagerungen = await new Parse.Query(ArtikelEinlagerung)
        .equalTo("artikel", this.artikel)
        .include("lager")
        .include("lagerort")
        .find();

      const bestaende = await new Parse.Query(ArtikelBestand)
        .include("lager")
        .equalTo("artikel", this.artikel)
        .find();

      bestaende.sort((a, b) => {
        return getArtikelBestandIndex(a) - getArtikelBestandIndex(b);
      });

      einlagerungen.sort((a, b) => {
        return getArtikelEinlagerungIndex(a) - getArtikelEinlagerungIndex(b);
      });

      runInAction(() => {
        this.bestaende = bestaende;
        this.einlagerungen = einlagerungen;

        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}

function getArtikelBestandIndex(bestand) {
  return parseInt(JSON.parse(_optionalChain([bestand, 'access', _ => _.get, 'call', _2 => _2("lager"), 'optionalAccess', _3 => _3.get, 'call', _4 => _4("sqlRef")])));
}

function getArtikelEinlagerungIndex(einlagerung) {
  return parseFloat(
    JSON.parse(_optionalChain([einlagerung, 'access', _5 => _5.get, 'call', _6 => _6("lagerort"), 'optionalAccess', _7 => _7.get, 'call', _8 => _8("sqlRef")]) || "[0,0]").join(".")
  );
}
