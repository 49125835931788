import Parse from "parse";













export class BDE_Form extends Parse.Object {
  static __initStatic() {this.className = "BDE_Form"}

  constructor(data) {
    super("BDE_Form", data );
  }

  get entries() {
    return super.get("entries");
  }
  set entries(value) {
    super.set("entries", value);
  }
  get form() {
    return super.get("form");
  }
  set form(value) {
    super.set("form", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
  get RID() {
    return super.get("RID");
  }
  set RID(value) {
    super.set("RID", value);
  }
} BDE_Form.__initStatic();

Parse.Object.registerSubclass("BDE_Form", BDE_Form);
