 function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { message, Modal } from "antd";
import { makeAutoObservable, runInAction } from "mobx";
import * as Parse from "parse";

import { ArtikelBestandState } from "../state/ArtikelBestandState";

export class UmbuchungState {
  

   __init() {this.loading = true}

   __init2() {this.started = false}
   __init3() {this.done = false}

   __init4() {this.artikel = undefined}
   __init5() {this.artikelBestand = undefined}

   __init6() {this.charge = undefined}
   __init7() {this.seriennummer = undefined}
   __init8() {this.menge = 0}

   __init9() {this.startLager = undefined}
   __init10() {this.startLagerort = undefined}

   __init11() {this.zielLager = undefined}
   __init12() {this.zielLagerort = undefined}

  constructor(type) {;UmbuchungState.prototype.__init.call(this);UmbuchungState.prototype.__init2.call(this);UmbuchungState.prototype.__init3.call(this);UmbuchungState.prototype.__init4.call(this);UmbuchungState.prototype.__init5.call(this);UmbuchungState.prototype.__init6.call(this);UmbuchungState.prototype.__init7.call(this);UmbuchungState.prototype.__init8.call(this);UmbuchungState.prototype.__init9.call(this);UmbuchungState.prototype.__init10.call(this);UmbuchungState.prototype.__init11.call(this);UmbuchungState.prototype.__init12.call(this);
    this.type = type;

    makeAutoObservable(this);
  }

  get validationError() {
    if (!this.artikel) {
      return "Kein Artikel ausgewählt.";
    }

    if (!this.startLager) {
      return "Kein Quell-Lager ausgewählt.";
    }

    if (!this.zielLager) {
      return "Kein Ziel-Lager ausgewählt.";
    }

    if (
      !this.startLagerort &&
      !this.zielLagerort &&
      this.startLager &&
      _optionalChain([this, 'access', _ => _.startLager, 'optionalAccess', _2 => _2.id]) === _optionalChain([this, 'access', _3 => _3.zielLager, 'optionalAccess', _4 => _4.id])
    ) {
      return "Das Quell-Lager darf nicht dem Ziel-Lager entsprechen.";
    }

    if (
      this.startLagerort &&
      _optionalChain([this, 'access', _5 => _5.startLagerort, 'optionalAccess', _6 => _6.id]) === _optionalChain([this, 'access', _7 => _7.zielLagerort, 'optionalAccess', _8 => _8.id])
    ) {
      return "Der Quell-Lagerort darf nicht dem Ziel-Lagerort entsprechen.";
    }

    if (!this.menge) {
      return "Die Menge muss größer 0 sein.";
    }

    if (this.artikel.seriennummer && !this.seriennummer) {
      return "Es fehlt die Angabe der Seriennummer.";
    }

    if (this.artikel.chargenverfolgung && !this.charge) {
      return "Es fehlt die Angabe der Chargennummer.";
    }

    return undefined;
  }

   setArtikel(artikel) {
    if (artikel) {
      this.artikel = artikel;
      this.artikelBestand = new ArtikelBestandState(artikel);
    } else {
      this.artikel = undefined;
      this.artikelBestand = undefined;
    }
  }

   setStartLager(
    lager,
    lagerort
  ) {
    this.startLager = lager;
    this.startLagerort = lagerort;
  }

   setZielLager(
    lager,
    lagerort
  ) {
    this.zielLager = lager;
    this.zielLagerort = lagerort;
  }

   async submit() {
    const hide = message.loading("Umbuchung wird durchgeführt...", 0);

    try {
      const buchung = new Parse.Object("APP_Job_Umbuchung", {
        gebucht: false,
        successful: null,
        startLager: this.startLager,
        startLagerort: this.startLagerort,
        zielLager: this.zielLager,
        zielLagerort: this.zielLagerort,
        artikel: this.artikel,
        menge: this.seriennummer ? 1 : this.menge,
        charge: this.charge,
        seriennummer: this.seriennummer,
      });

      await buchung.save();

      for (let i = 0; i < 10; i++) {
        await delay(1000);

        await buchung.fetch();

        if (buchung.get("gebucht")) {
          break;
        }
      }

      hide();

      if (!buchung.get("gebucht")) {
        throw new Error("Timeout");
      }

      if (!buchung.get("successful")) {
        return void Modal.error({
          title: "Fehler in der Buchung",
          content: _optionalChain([buchung, 'access', _9 => _9.get, 'call', _10 => _10("response"), 'optionalAccess', _11 => _11.answer]),
        });
      }

      runInAction(() => {
        this.done = true;

        if (this.type !== "lager") {
          this.started = false;

          this.setStartLager(undefined, undefined);
        }

        this.setArtikel(undefined);
        this.setZielLager(undefined, undefined);

        this.menge = 0;
        this.seriennummer = "";
        this.charge = "";
      });
    } catch (error) {
      console.error("Buchungs Error", error);

      let msg = "Unbekannter Fehler";

      if (_optionalChain([(error ), 'optionalAccess', _12 => _12.message]) === "Timeout") {
        msg = "Timeout";
      }

      Modal.error({
        title: "Fehler in der Umbuchung",
        content: `Umbuchung wurde nicht durchgeführt (${msg})`,
      });

      hide();
    }

    return;
  }
}

function delay(ms = 1000) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  });
}
