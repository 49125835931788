import Parse from "parse";




























export class open_transaction_stckl extends Parse.Object {
  static __initStatic() {this.className = "open_transaction_stckl"}

  constructor(data) {
    super("open_transaction_stckl", data );
  }

  get artikel() {
    return super.get("artikel");
  }
  set artikel(value) {
    super.set("artikel", value);
  }
  get artikelnr() {
    return super.get("artikelnr");
  }
  set artikelnr(value) {
    super.set("artikelnr", value);
  }
  get auftrag() {
    return super.get("auftrag");
  }
  set auftrag(value) {
    super.set("auftrag", value);
  }
  get auftragposition() {
    return super.get("auftragposition");
  }
  set auftragposition(value) {
    super.set("auftragposition", value);
  }
  get bezeichnung() {
    return super.get("bezeichnung");
  }
  set bezeichnung(value) {
    super.set("bezeichnung", value);
  }
  get lager() {
    return super.get("lager");
  }
  set lager(value) {
    super.set("lager", value);
  }
  get lagerort() {
    return super.get("lagerort");
  }
  set lagerort(value) {
    super.set("lagerort", value);
  }
  get menge() {
    return super.get("menge");
  }
  set menge(value) {
    super.set("menge", value);
  }
  get position() {
    return super.get("position");
  }
  set position(value) {
    super.set("position", value);
  }
  get stckList() {
    return super.get("stckList");
  }
  set stckList(value) {
    super.set("stckList", value);
  }
  get stkListPos() {
    return super.get("stkListPos");
  }
  set stkListPos(value) {
    super.set("stkListPos", value);
  }
} open_transaction_stckl.__initStatic();

Parse.Object.registerSubclass("open_transaction_stckl", open_transaction_stckl);
