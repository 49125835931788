import Parse from "parse";






























export class AuftragsstuecklistenPosition extends Parse.Object {
  static __initStatic() {this.className = "AuftragsstuecklistenPosition"}

  constructor(data) {
    super("AuftragsstuecklistenPosition", data );
  }

  get artikel() {
    return super.get("artikel");
  }
  set artikel(value) {
    super.set("artikel", value);
  }
  get artikelnr() {
    return super.get("artikelnr");
  }
  set artikelnr(value) {
    super.set("artikelnr", value);
  }
  get auftragsstueckliste() {
    return super.get("auftragsstueckliste");
  }
  set auftragsstueckliste(value) {
    super.set("auftragsstueckliste", value);
  }
  get BDENummer() {
    return super.get("BDENummer");
  }
  set BDENummer(value) {
    super.set("BDENummer", value);
  }
  get bezeichnung() {
    return super.get("bezeichnung");
  }
  set bezeichnung(value) {
    super.set("bezeichnung", value);
  }
  get fertig() {
    return super.get("fertig");
  }
  set fertig(value) {
    super.set("fertig", value);
  }
  get generiertUE() {
    return super.get("generiertUE");
  }
  set generiertUE(value) {
    super.set("generiertUE", value);
  }
  get gesperrt() {
    return super.get("gesperrt");
  }
  set gesperrt(value) {
    super.set("gesperrt", value);
  }
  get menge() {
    return super.get("menge");
  }
  set menge(value) {
    super.set("menge", value);
  }
  get mengeAngefordertNichtBestellt() {
    return super.get("mengeAngefordertNichtBestellt");
  }
  set mengeAngefordertNichtBestellt(value) {
    super.set("mengeAngefordertNichtBestellt", value);
  }
  get mengeBestelltWehn() {
    return super.get("mengeBestelltWehn");
  }
  set mengeBestelltWehn(value) {
    super.set("mengeBestelltWehn", value);
  }
  get mengeIst() {
    return super.get("mengeIst");
  }
  set mengeIst(value) {
    super.set("mengeIst", value);
  }
  get mengeSoll() {
    return super.get("mengeSoll");
  }
  set mengeSoll(value) {
    super.set("mengeSoll", value);
  }
  get position() {
    return super.get("position");
  }
  set position(value) {
    super.set("position", value);
  }
  get sqlCreatedAt() {
    return super.get("sqlCreatedAt");
  }
  set sqlCreatedAt(value) {
    super.set("sqlCreatedAt", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
  get sqlUpdatedAt() {
    return super.get("sqlUpdatedAt");
  }
  set sqlUpdatedAt(value) {
    super.set("sqlUpdatedAt", value);
  }
  get teilfertig() {
    return super.get("teilfertig");
  }
  set teilfertig(value) {
    super.set("teilfertig", value);
  }
} AuftragsstuecklistenPosition.__initStatic();

Parse.Object.registerSubclass("AuftragsstuecklistenPosition", AuftragsstuecklistenPosition);
