const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\wehn-web\\src\\js\\components\\Layout.tsx";import { AdminLayout } from "@opendash/core";
import { Divider, PageHeader } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  .ant-list-item-meta-avatar {
    .anticon {
      font-size: 24px !important;
      line-height: 48px !important;
    }
  }
`;












export const Layout = observer(function Layout({
  title,
  description,
  padding,
  back,
  breadcrumb,
  children,
  extra,
  hideDivider,
}) {
  const navigate = useNavigate();

  return (
    React.createElement(AdminLayout, { contentPadding: padding, __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
      , React.createElement(Container, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
        , React.createElement(PageHeader, {
          onBack: !back ? undefined : () => navigate(back ),
          title: title,
          subTitle: description,
          style: padding ? { padding: 0 } : undefined,
          breadcrumb: 
            !breadcrumb
              ? undefined
              : {
                  routes: breadcrumb.map((b) => ({
                    path: b.path,
                    breadcrumbName: b.label,
                  })),
                  itemRender: (route) =>
                    route.path ? (
                      React.createElement(Link, { to: route.path, children: route.breadcrumbName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}} )
                    ) : (
                      React.createElement('span', { children: route.breadcrumbName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}} )
                    ),
                }
          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}

          , extra || null
          , !hideDivider && React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 66}} )
        )

        , children
      )
    )
  );
});
