const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\wehn-web\\src\\js\\page\\WehnEntnahme.tsx"; function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { Icon } from "@opendash/icons";
import { Empty, List, Select } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link } from "react-router-dom";
import { Layout } from "../components/Layout";
import { $wehn } from "../state";

export const WehnEntnahme = observer(function WehnEntnahme() {
  return (
    React.createElement(Layout, {
      title: "Entnahme",
      description: "aus Auftrag" ,
      padding: true,
      back: null,
      breadcrumb: [
        {
          path: `/`,
          label: "Startseite",
        },
        {
          path: "",
          label: "Entnahme",
        },
      ],
      extra: 
        React.createElement(Select, {
          placeholder: "Auftrag auswählen" ,
          size: "large",
          value: _optionalChain([$wehn, 'access', _ => _.orders, 'access', _2 => _2.auftrag, 'optionalAccess', _3 => _3.id]),
          options: $wehn.orders.getOrderOptions(),
          onChange: (v) => $wehn.orders.setOrder(v),
          style: { width: "100%" },
          showSearch: true,
          optionFilterProp: "children",
          filterOption: (input, option) =>
            (_nullishCoalesce(_optionalChain([option, 'optionalAccess', _4 => _4.label]), () => ( ""))).toLowerCase().includes(input.toLowerCase())
          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
        )
      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}

      , !$wehn.orders.auftrag && (
        React.createElement(Empty, {
          description: "Es wurde kein Auftrag ausgewählt."    ,
          image: Empty.PRESENTED_IMAGE_SIMPLE, __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
        )
      )

      , !!$wehn.orders.auftrag && (
        React.createElement(List, {
          dataSource: $wehn.orders.getListItems(),
          rowKey: "key",
          renderItem: (item) => {
            return (
              React.createElement(Link, { to: item.link, __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
                , React.createElement(List.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
                  , React.createElement(List.Item.Meta, {
                    title: item.title,
                    description: item.description,
                    avatar: React.createElement(Icon, { icon: "fa:list", __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
                  )

                  , React.createElement(Icon, { icon: "fa:chevron-right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}} )
                )
              )
            );
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
        )
      )
    )
  );
});
