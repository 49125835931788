import Parse from "parse";























export class APP_Job_Umbuchung extends Parse.Object {
  static __initStatic() {this.className = "APP_Job_Umbuchung"}

  constructor(data) {
    super("APP_Job_Umbuchung", data );
  }

  get artikel() {
    return super.get("artikel");
  }
  set artikel(value) {
    super.set("artikel", value);
  }
  get gebucht() {
    return super.get("gebucht");
  }
  set gebucht(value) {
    super.set("gebucht", value);
  }
  get menge() {
    return super.get("menge");
  }
  set menge(value) {
    super.set("menge", value);
  }
  get startLager() {
    return super.get("startLager");
  }
  set startLager(value) {
    super.set("startLager", value);
  }
  get startLagerort() {
    return super.get("startLagerort");
  }
  set startLagerort(value) {
    super.set("startLagerort", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
  get zielLager() {
    return super.get("zielLager");
  }
  set zielLager(value) {
    super.set("zielLager", value);
  }
  get zielLagerort() {
    return super.get("zielLagerort");
  }
  set zielLagerort(value) {
    super.set("zielLagerort", value);
  }
} APP_Job_Umbuchung.__initStatic();

Parse.Object.registerSubclass("APP_Job_Umbuchung", APP_Job_Umbuchung);
