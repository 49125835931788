import Parse from "parse";












export class BuchungExtern extends Parse.Object {
  static __initStatic() {this.className = "BuchungExtern"}

  constructor(data) {
    super("BuchungExtern", data );
  }

  get message() {
    return super.get("message");
  }
  set message(value) {
    super.set("message", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
  get successful() {
    return super.get("successful");
  }
  set successful(value) {
    super.set("successful", value);
  }
} BuchungExtern.__initStatic();

Parse.Object.registerSubclass("BuchungExtern", BuchungExtern);
