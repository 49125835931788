const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\wehn-web\\src\\js\\page\\WehnUmbuchung.tsx";import { observer } from "mobx-react-lite";
import * as React from "react";
import { useParams } from "react-router-dom";
import { Layout } from "../components/Layout";
import { Umbuchung } from "../components/Umbuchung";

export const WehnUmbuchung = observer(function WehnUmbuchung() {
  const { type } = useParams();

  if (type !== "artikel" && type !== "lager") {
    return null;
  }

  return (
    React.createElement(Layout, {
      title: 
        type === "artikel"
          ? "Artikel-Umbuchung"
          : type === "lager"
          ? "Lager-Umbuchung"
          : ""
      ,
      description: "",
      padding: true,
      back: "/",
      breadcrumb: [
        {
          path: `/`,
          label: "Startseite",
        },
        {
          path: "",
          label: "Umbuchung",
        },
      ],
      hideDivider: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}

      , React.createElement(Umbuchung, { key: type, type: type, __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}} )
    )
  );
});
