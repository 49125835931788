const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\wehn-web\\src\\js\\page\\WehnArtikel.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { Button, Divider, Input, List } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Link } from "react-router-dom";
import { Layout } from "../components/Layout";
import { useParseQuery } from "parse-hooks";
import * as Parse from "parse";

export const WehnArtikel = observer(function WehnArtikel() {
  const [searchString, setSearchString] = React.useState("");
  const [searchStringDebounce, setSearchStringDebounce] = React.useState("");

  const query = React.useMemo(
    () =>
      searchStringDebounce
        ? new Parse.Query("Artikel").contains(
            "suchindex",
            searchStringDebounce.toLowerCase()
          )
        : null,
    [searchStringDebounce]
  );

  const result = useParseQuery(query);

  return (
    React.createElement(Layout, {
      title: "Artikelsuche",
      description: "",
      padding: true,
      back: -1,
      breadcrumb: [
        {
          path: `/`,
          label: "Startseite",
        },
        {
          path: "",
          label: "Artikel",
        },
      ],
      hideDivider: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}

      , React.createElement(React.Fragment, null
        , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}, "Geben Sie die Artikelnummer oder eine Bezeichnung ein."       )
        , React.createElement(Input, {
          placeholder: "Artikelnummer oder Bezeichnung"  ,
          style: { width: "100%" },
          value: searchString,
          onChange: (e) => {
            setSearchString(e.target.value);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
        )
        , React.createElement(Button, {
          type: "primary",
          style: { marginTop: "10px", width: "100%" },
          disabled: !searchString || _optionalChain([searchString, 'optionalAccess', _ => _.length]) === 0,
          onClick: () => {
            setSearchStringDebounce(searchString);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
, "Suchen"

        )

        , !!searchStringDebounce && (
          React.createElement(React.Fragment, null
            , React.createElement(Divider, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 67}} )

            , React.createElement(List, {
              loading: result.loading,
              rowKey: (row) => row.id,
              dataSource: result.result,
              renderItem: (row) => {
                return (
                  React.createElement(Link, { to: `/wehn/artikel/${row.get("artikelnummer")}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}
                    , React.createElement(List.Item, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
                      , React.createElement(List.Item.Meta, {
                        title: row.get("bezeichnung"),
                        description: row.get("artikelnummer"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
                      )
                    )
                  )
                );
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
            )
          )
        )
      )
    )
  );
});
