const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\wehn-web\\src\\js\\components\\BuchungenListe.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { Table, Tag } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";







export const BuchungenListe = observer(function BuchungenListe({
  buchungen,
  showLager = undefined,
}) {
  return (
    React.createElement(Table, {
      size: "small",
      dataSource: buchungen,
      rowKey: (row) => row.id,
      pagination: false,
      columns: [
        {
          title: "Art",
          key: "art",
          render: (_, row) => (
            React.createElement(Tag, { color: row.get("menge") > 0 ? "green" : "red", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
              , row.get("menge") > 0 ? "E" : "R"
            )
          ),
        },
        {
          title: "Datum",
          key: "date",
          render: (_, row) => {
            return _optionalChain([row, 'access', _2 => _2.get, 'call', _3 => _3("buchungsdatum"), 'optionalAccess', _4 => _4.toLocaleDateString, 'call', _5 => _5("de-DE", {
              year: "numeric",
              month: "numeric",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            })]);
          },
        },
        {
          title: "Nutzer",
          key: "user",
          render: (_, row) =>
            row.get("nutzerKartennummer") || "Unbekannter Nutzer",
        },
        ...(showLager
          ? [
              {
                title: "Lager(ort)",
                key: "lagerort",
                render: (_, row) => {
                  if (_optionalChain([row, 'optionalAccess', _6 => _6.get, 'call', _7 => _7("lagerort")])) {
                    return displayLagerortSQLRef(
                      _optionalChain([row, 'optionalAccess', _8 => _8.get, 'call', _9 => _9("lagerort")]) 
                    );
                  }

                  if (_optionalChain([row, 'optionalAccess', _10 => _10.get, 'call', _11 => _11("lagerort")])) {
                    return React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}, _optionalChain([row, 'access', _12 => _12.get, 'call', _13 => _13("lager"), 'optionalAccess', _14 => _14.get, 'call', _15 => _15("sqlRef")]));
                  }

                  return "-";
                },
              },
            ]
          : []),
        {
          title: "Menge",
          key: "menge",
          render: (_, row) => (
            React.createElement('b', {
              style: {
                color: row.get("menge") < 0 ? "red" : "green",
                float: "right",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}

              , row.get("menge"), " " , _optionalChain([row, 'access', _16 => _16.get, 'call', _17 => _17("artikel"), 'optionalAccess', _18 => _18.get, 'call', _19 => _19("mengeneinheit")])
            )
          ),
        },
      ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
    )
  );
});

function displayLagerortSQLRef(lagerort) {
  try {
    return JSON.parse(_optionalChain([lagerort, 'optionalAccess', _20 => _20.get, 'call', _21 => _21("sqlRef")]) ).join("/");
  } catch (error) {
    return "";
  }
}
