const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\wehn-web\\src\\js\\components\\BestandListe.state.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { Tag } from "antd";
import { makeAutoObservable, runInAction } from "mobx";
import * as Parse from "parse";
import React from "react";
import {
  Artikel,
  ArtikelBestand,
  ArtikelEinlagerung,

} from "../parse-types";








export class BestandListeState {
   __init() {this.loading = true}
   __init2() {this.artikel = undefined}
   __init3() {this.einlagerungen = []}
   __init4() {this.bestaende = []}

  constructor(id) {;BestandListeState.prototype.__init.call(this);BestandListeState.prototype.__init2.call(this);BestandListeState.prototype.__init3.call(this);BestandListeState.prototype.__init4.call(this);
    makeAutoObservable(this);

    this.fetchData(id);

    // console.log("BestandListeState", id, this);
  }

  get data() {
    const result = [];

    for (const bestand of this.bestaende) {
      const bestandMenge = bestand.get("bestandLaufend") || 0;
      const einheit = _optionalChain([this, 'access', _ => _.artikel, 'optionalAccess', _2 => _2.get, 'call', _3 => _3("mengeneinheit")]);

      const children = this.einlagerungen
        .filter((einlagerung) => {
          return _optionalChain([einlagerung, 'access', _4 => _4.get, 'call', _5 => _5("lager"), 'optionalAccess', _6 => _6.id]) === _optionalChain([bestand, 'access', _7 => _7.get, 'call', _8 => _8("lager"), 'optionalAccess', _9 => _9.id]);
        })
        .map((einlagerung) => {
          const einlagerungMenge = _optionalChain([einlagerung, 'optionalAccess', _10 => _10.get, 'call', _11 => _11("menge")]) || 0;

          return {
            id: einlagerung.id,
            label: displayLagerortSQLRef(
              _optionalChain([einlagerung, 'optionalAccess', _12 => _12.get, 'call', _13 => _13("lagerort")]) 
            ),
            // menge: einlagerungMenge,
            menge: (
              React.createElement(Tag, { color: einlagerungMenge > 0 ? "green" : "red", __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
                , einlagerungMenge + " " + einheit
              )
            ),
          };
        });

      result.push({
        id: bestand.id,
        label: _optionalChain([bestand, 'access', _14 => _14.get, 'call', _15 => _15("lager"), 'optionalAccess', _16 => _16.get, 'call', _17 => _17("sqlRef")]),
        // menge: bestandMenge,
        menge: (
          React.createElement(Tag, { color: bestandMenge > 0 ? "green" : "red", __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
            , bestandMenge + " " + einheit
          )
        ),
        children: children.length > 0 ? children : undefined,
      });
    }

    return result;
  }

   async fetchData(id) {
    try {
      const artikel = await new Parse.Query(Artikel)
        .equalTo("artikelnummer", id)
        .first();

      const einlagerungen = await new Parse.Query(ArtikelEinlagerung)
        .equalTo("artikel", artikel)
        .include("lager")
        .include("lagerort")
        .find();

      const bestaende = await new Parse.Query(ArtikelBestand)
        .include("lager")
        .equalTo("artikel", artikel)
        .find();

      bestaende.sort((a, b) => {
        return getArtikelBestandIndex(a) - getArtikelBestandIndex(b);
      });

      einlagerungen.sort((a, b) => {
        return getArtikelEinlagerungIndex(a) - getArtikelEinlagerungIndex(b);
      });

      runInAction(() => {
        this.artikel = artikel;
        this.bestaende = bestaende;
        this.einlagerungen = einlagerungen;

        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
      });
    }
  }
}

function displayLagerortSQLRef(lagerort) {
  try {
    return JSON.parse(_optionalChain([lagerort, 'optionalAccess', _18 => _18.get, 'call', _19 => _19("sqlRef")]) ).join("/");
  } catch (error) {
    return "";
  }
}

function getArtikelBestandIndex(bestand) {
  return parseInt(JSON.parse(_optionalChain([bestand, 'access', _20 => _20.get, 'call', _21 => _21("lager"), 'optionalAccess', _22 => _22.get, 'call', _23 => _23("sqlRef")])));
}

function getArtikelEinlagerungIndex(einlagerung) {
  return parseFloat(
    JSON.parse(_optionalChain([einlagerung, 'access', _24 => _24.get, 'call', _25 => _25("lagerort"), 'optionalAccess', _26 => _26.get, 'call', _27 => _27("sqlRef")]) || "[0,0]").join(".")
  );
}
