import Parse from "parse";





























export class Buchung extends Parse.Object {
  static __initStatic() {this.className = "Buchung"}

  constructor(data) {
    super("Buchung", data );
  }

  get artikel() {
    return super.get("artikel");
  }
  set artikel(value) {
    super.set("artikel", value);
  }
  get auftrag() {
    return super.get("auftrag");
  }
  set auftrag(value) {
    super.set("auftrag", value);
  }
  get auftragsPosition() {
    return super.get("auftragsPosition");
  }
  set auftragsPosition(value) {
    super.set("auftragsPosition", value);
  }
  get auftragsstueckliste() {
    return super.get("auftragsstueckliste");
  }
  set auftragsstueckliste(value) {
    super.set("auftragsstueckliste", value);
  }
  get auftragsstuecklistenPosition() {
    return super.get("auftragsstuecklistenPosition");
  }
  set auftragsstuecklistenPosition(value) {
    super.set("auftragsstuecklistenPosition", value);
  }
  get buchungsdatum() {
    return super.get("buchungsdatum");
  }
  set buchungsdatum(value) {
    super.set("buchungsdatum", value);
  }
  get lager() {
    return super.get("lager");
  }
  set lager(value) {
    super.set("lager", value);
  }
  get lagerort() {
    return super.get("lagerort");
  }
  set lagerort(value) {
    super.set("lagerort", value);
  }
  get menge() {
    return super.get("menge");
  }
  set menge(value) {
    super.set("menge", value);
  }
  get mengeneinheit() {
    return super.get("mengeneinheit");
  }
  set mengeneinheit(value) {
    super.set("mengeneinheit", value);
  }
  get nutzerKartennummer() {
    return super.get("nutzerKartennummer");
  }
  set nutzerKartennummer(value) {
    super.set("nutzerKartennummer", value);
  }
  get sqlRef() {
    return super.get("sqlRef");
  }
  set sqlRef(value) {
    super.set("sqlRef", value);
  }
} Buchung.__initStatic();

Parse.Object.registerSubclass("Buchung", Buchung);
