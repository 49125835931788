const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\wehn-web\\src\\js\\components\\ArtikelInfoDrawer.tsx";import { Drawer } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { $wehn } from "../state";
import { ArtikelInfoShow } from "./ArtikelInfoShow";

export const AritkelInfoDrawer = observer(function AritkelInfoDrawer() {
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();

  const artikel = params.get("artikelinfo");

  React.useEffect(() => {
    if ($wehn.artikelinfo && !artikel) {
      params.set("artikelinfo", $wehn.artikelinfo);
      setParams(params);
    }
  }, [$wehn.artikelinfo]);

  React.useEffect(() => {
    if (!artikel) {
      $wehn.setArtikelInfoDrawer(null);
    }

    if (artikel && !$wehn.artikelinfo) {
      $wehn.setArtikelInfoDrawer(artikel);
    }
  }, [artikel]);

  if ($wehn.artikelinfo) {
    return (
      // @ts-ignore
      React.createElement(Drawer, {
        title: "Artikelinformationen",
        open: true,
        height: window.innerHeight,
        placement: "bottom",
        onClose: () => {
          navigate(-1);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}

        , React.createElement(ArtikelInfoShow, { artikelId: $wehn.artikelinfo, __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}} )
      )
    );
  }

  return (
    React.createElement(Drawer, { title: "Artikelinformationen", open: false, placement: "bottom", __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}} )
  );
});
