const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\wehn-web\\src\\js\\page\\WehnArtikelInfo.tsx";import { observer } from "mobx-react-lite";
import * as React from "react";
import { useParams } from "react-router-dom";
import { ArtikelInfoShow } from "../components/ArtikelInfoShow";
import { Layout } from "../components/Layout";

export const WehnArtikelInfo = observer(function WehnArtikelInfo() {
  const { artikel: artikelId } = useParams();

  return (
    React.createElement(Layout, {
      title: "Artikelinformationen",
      description: "",
      padding: true,
      back: "/",
      breadcrumb: [
        {
          path: `/`,
          label: "Startseite",
        },
        {
          path: "/wehn/artikel",
          label: "Artikel",
        },
        {
          path: "",
          label: artikelId,
        },
      ],
      extra: null,
      hideDivider: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}

      , React.createElement(ArtikelInfoShow, { artikelId: artikelId, __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}} )
    )
  );
});
