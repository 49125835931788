const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\wehn-web\\src\\js\\components\\Global.tsx";import { observer } from "mobx-react-lite";
import * as React from "react";
import { ArtikelEntnahmeDrawer } from "./ArtikelEntnahmeDrawer";
import { AritkelInfoDrawer } from "./ArtikelInfoDrawer";

import * as styled from "styled-components";

const GlobalStyle = styled.createGlobalStyle`
  .ant-select-tree-node-content-wrapper {
    padding: 8px !important;
  }

  .ant-select-tree-switcher {
    display: none !important;
  }

  .ant-select-item-option-content {
    line-height: 40px;
  }
`;

export const Global = observer(function Global() {
  return (
    React.createElement(React.Fragment, null
      , React.createElement(GlobalStyle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 25}} )
      , React.createElement(AritkelInfoDrawer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 26}} )
      , React.createElement(ArtikelEntnahmeDrawer, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 27}} )
    )
  );
});
