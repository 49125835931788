import Parse from "parse";











export class BDE_Page extends Parse.Object {
  static __initStatic() {this.className = "BDE_Page"}

  constructor(data) {
    super("BDE_Page", data );
  }

  get data() {
    return super.get("data");
  }
  set data(value) {
    super.set("data", value);
  }
  get pageID() {
    return super.get("pageID");
  }
  set pageID(value) {
    super.set("pageID", value);
  }
} BDE_Page.__initStatic();

Parse.Object.registerSubclass("BDE_Page", BDE_Page);
