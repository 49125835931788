import { makeAutoObservable } from "mobx";
import { BuchungService } from "./BuchungService";
import { OrderService } from "./OrderService";

class WehnService {
  __init() {this.artikelinfo = null}
   __init2() {this.buchung = new BuchungService()}
   __init3() {this.orders = new OrderService()}

  constructor() {;WehnService.prototype.__init.call(this);WehnService.prototype.__init2.call(this);WehnService.prototype.__init3.call(this);
    makeAutoObservable(this);

    // @ts-ignore
    window.$wehn = this;
  }

  setArtikelInfoDrawer(value) {
    this.artikelinfo = value;
  }

  async init() {
    await this.orders.init();
  }
}

export const $wehn = new WehnService();
