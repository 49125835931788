const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\wehn-web\\src\\js\\components\\ArtikelInput.tsx";import { AutoComplete, Form } from "antd";

import { makeAutoObservable, runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import * as Parse from "parse";
import * as React from "react";
import { Artikel } from "../parse-types";














class ArtikelInputState {
   __init() {this.value = ""}
   __init2() {this.artikel = undefined}
   __init3() {this.options = []}
   __init4() {this.result = []}

  

  constructor(props) {;ArtikelInputState.prototype.__init.call(this);ArtikelInputState.prototype.__init2.call(this);ArtikelInputState.prototype.__init3.call(this);ArtikelInputState.prototype.__init4.call(this);
    this.props = props;

    makeAutoObservable(this);

    if (this.props.value) {
      this.articleToValue(this.props.value);
    }
  }

   setProps(props) {
    const isControlled = "value" in props;

    this.props = props;

    if (this.props.value) {
      this.value = this.articleToValue(this.props.value);
    }
  }

   onSelect(value, option) {
    this.value = value;
    this.artikel = this.result.find((a) => a.id === option.artikelId);

    this.props.onChange(this.artikel);
  }

   async onSearch(searchText) {
    this.value = searchText;

    if (!searchText || searchText.length < 3) {
      this.result = [];
      this.options = [];
    } else {
      const result = await new Parse.Query(Artikel)
        .contains("suchindex", searchText.toLowerCase())
        .limit(5)
        .find();

      runInAction(() => {
        this.result = result;
        this.options = result.map((artikel) => ({
          value: this.articleToValue(artikel),
          artikelId: artikel.id,
        }));
      });
    }
  }

   articleToValue(artikel) {
    return `${artikel.artikelnummer} - ${artikel.bezeichnung}`;
  }
}

export const ArtikelInput = observer(function ArtikelInput(props) {
  const state = React.useMemo(() => new ArtikelInputState(props), []);

  React.useEffect(() => {
    state.setProps(props);
  }, [props.value]);

  return (
    React.createElement(Form.Item, { label: props.label, __self: this, __source: {fileName: _jsxFileName, lineNumber: 92}}
      , React.createElement(AutoComplete, {
        placeholder: "Artikelnummer oder -namen eingeben"   ,
        autoFocus: props.autoFocus || false,
        size: props.size,
        value: state.value,
        options: state.options,
        style: { width: "100%" },
        onSelect: (v, o) => state.onSelect(v, o),
        onSearch: (v) => state.onSearch(v), __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
      )
    )
  );
});
