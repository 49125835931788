const _jsxFileName = "C:\\Users\\sz\\Code\\open-inc\\wehn-web\\src\\js\\components\\LagerInput.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { Form, Input } from "antd";

import { makeAutoObservable } from "mobx";
import { observer } from "mobx-react-lite";
import * as Parse from "parse";
import * as React from "react";
import { Lager, Lagerort } from "../parse-types";












class LagerInputState {
   __init() {this.loading = false}
   __init2() {this.error = null}
   __init3() {this.value = ""}

  

  constructor(props) {;LagerInputState.prototype.__init.call(this);LagerInputState.prototype.__init2.call(this);LagerInputState.prototype.__init3.call(this);
    this.props = props;

    makeAutoObservable(this);
  }

  get validateStatus() {
    return !this.value
      ? undefined
      : this.loading
      ? "validating"
      : this.error
      ? "error"
      : "success";
  }

  get hasFeedback() {
    return !!this.value;
  }

  get help() {
    if (this.error) {
      return this.error;
    }

    if (this.props.bestand && this.props.lager) {
      const lager = this.props.lager;
      const lagerort = this.props.lagerort;
      const artikel = this.props.bestand.artikel;
      const bestaende = this.props.bestand.bestaende;
      const einlagerungen = this.props.bestand.einlagerungen;

      const bestand = bestaende.find(
        (b) => _optionalChain([b, 'access', _ => _.lager, 'optionalAccess', _2 => _2.id]) === _optionalChain([this, 'access', _3 => _3.props, 'access', _4 => _4.lager, 'optionalAccess', _5 => _5.id])
      );

      const einlagerung = einlagerungen.find(
        (e) =>
          e.sqlRef === JSON.stringify([_optionalChain([lager, 'optionalAccess', _6 => _6.id]), _optionalChain([lagerort, 'optionalAccess', _7 => _7.id]), _optionalChain([artikel, 'optionalAccess', _8 => _8.id])])
      );

      if (!bestand && !einlagerung) {
        if (lagerort) {
          return "An diesem Lagerort wurde keine Einlagerung für den gewählten Artikel gefunden.";
        } else {
          return "In diesem Lager wurde kein Bestand für den gewählten Artikel gefunden.";
        }
      }
    }

    return undefined;
  }

   setProps(props) {
    this.props = props;
  }

   onSelect(
    error,
    lager,
    lagerort
  ) {
    this.loading = false;
    this.error = error;

    this.props.onChange(lager, lagerort);
  }

   async onChange(value) {
    this.value = value;
    this.loading = true;

    if (!value) {
      return this.onSelect(null);
    }

    try {
      const parts =
        value.length === 12
          ? [value.slice(0, 6), value.slice(6, 12)]
          : value.length === 6
          ? [value]
          : value.length === 13 && value.includes("/")
          ? value.split("/")
          : [];

      if (parts.length === 1) {
        const lager = await new Parse.Query(Lager)
          .equalTo("sqlRef", parts[0])
          .first();

        if (lager) {
          this.onSelect(null, lager);
        } else {
          this.onSelect("Lager nicht gefunden");
        }
      } else if (parts.length === 2) {
        const lager = await new Parse.Query(Lager)
          .equalTo("sqlRef", parts[0])
          .first();

        const lagerort = await new Parse.Query(Lagerort)
          .equalTo("sqlRef", JSON.stringify(parts))
          .first();

        if (lager && lagerort) {
          this.onSelect(null, lager, lagerort);
        } else {
          this.onSelect("Lagerort nicht gefunden");
        }
      } else {
        this.onSelect("Ungültiges Format");
      }
    } catch (err) {
      this.onSelect("Unbekannter Fehler");
    }
  }
}

export const LagerInput = observer(function LagerInput(props) {
  const state = React.useMemo(() => new LagerInputState(props), []);

  React.useEffect(() => {
    state.setProps(props);
  }, [_optionalChain([props, 'access', _9 => _9.lager, 'optionalAccess', _10 => _10.id]), _optionalChain([props, 'access', _11 => _11.lagerort, 'optionalAccess', _12 => _12.id]), props.bestand]);

  return (
    React.createElement(Form.Item, {
      label: props.label,
      validateStatus: state.validateStatus,
      hasFeedback: state.hasFeedback,
      help: state.help, __self: this, __source: {fileName: _jsxFileName, lineNumber: 154}}

      , React.createElement(Input, {
        placeholder: "Lager- oder Lagerortnummer eingeben"   ,
        autoFocus: props.autoFocus || false,
        size: props.size,
        value: state.value,
        style: { width: "100%" },
        onChange: (e) => {
          state.onChange(e.target.value);
        },
        suffix: React.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 169}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 160}}
      )
    )
  );
});
