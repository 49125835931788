import { makeAutoObservable, runInAction } from "mobx";
import Parse from "parse";
import { Auftrag, Auftragsposition, Auftragsstueckliste } from "../parse-types";
import { EntnahmeListService } from "./EntnahmeListService";

export class OrderService {
   __init() {this.orders = []}
   __init2() {this.auftrag = null}

   __init3() {this.auftragspositionen = []}
   __init4() {this.auftragsstuecklisten = []}

   __init5() {this.list = new EntnahmeListService(this)}

  constructor() {;OrderService.prototype.__init.call(this);OrderService.prototype.__init2.call(this);OrderService.prototype.__init3.call(this);OrderService.prototype.__init4.call(this);OrderService.prototype.__init5.call(this);
    makeAutoObservable(this);
  }

   async setOrder(id) {
    const order = this.orders.find((o) => o.id === id) || null;

    if (order) {
      window.localStorage.setItem("wehn/order/current", order.id);
    } else {
      window.localStorage.removeItem("wehn/order/current");
    }

    runInAction(() => {
      this.auftrag = order;
    });

    if (order) {
      const auftragsstuecklisten = await new Parse.Query(Auftragsstueckliste)
        .equalTo("auftrag", order)
        .ascending("hierarchyRang")
        .limit(1000000)
        .find();

      const auftragspositionen = await new Parse.Query(Auftragsposition)
        .equalTo("auftrag", order)
        // .ascending("")
        .limit(1000000)
        .find();

      runInAction(() => {
        this.auftragspositionen = auftragspositionen;
        this.auftragsstuecklisten = auftragsstuecklisten;
      });
    }
  }

   getOrderOptions() {
    return this.orders.map((o) => ({
      value: o.id,
      label: `${o.auftragsNummer} - ${o.bezeichnung}`,
    }));
  }

   getListItems() {
    const items




 = [];

    for (const x of this.auftragsstuecklisten) {
      if (x.hierarchyEbene === 1) {
        items.push({
          key: x.id,
          title: `${x.bezeichnung} - ${x.BDENummer}`,
          description: "Entnahme aus Stückliste",
          link: `/wehn/entnahme/auftragsstueckliste/${x.id}`,
        });
      }
    }

    if (this.auftragspositionen.length > 0) {
      items.push({
        key: "auftragspositionen",
        title: "Auftragspositionen",
        description: "Entnahme aus Auftragspositionen",
        link: `/wehn/entnahme/auftragspositionen`,
      });
    }

    return items;
  }

   async init() {
    const orders = await new Parse.Query(Auftrag)
      .ascending("auftragsNummer")
      .limit(1000000)
      .find();

    runInAction(() => {
      this.orders = orders;
    });

    this.setOrder(window.localStorage.getItem("wehn/order/current"));
  }

   toTree(parent) {
    return this.auftragsstuecklisten
      .filter((x) => {
        if (parent) {
          return x.hierarchyParent === parent;
        } else {
          return x.hierarchyEbene === 1;
        }
      })
      .map((x) => {
        return {
          value: x.id,
          label: `${x.bezeichnung} - ${x.BDENummer}`,
          children: this.toTree(x.BDENummer || "-"),
        };
      });
  }
}






